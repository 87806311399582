import { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { Pen, PlusCircle, Trash } from "react-bootstrap-icons";
import { apiService } from '../../functions/api-service';
import ModalMedicineUse from "../../components/modalMedicineUse";
import Swal from 'sweetalert2';
import { Alert } from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import { MedUse } from "../../type";
import {useNavigate} from "react-router-dom";

export default function SettingMedicineUse() {
  const { isLoad, isLoaded } = useContexts();
  const [medUseList, setMedUseList] = useState<Array<MedUse>>([]);
  const [search, setSearch] = useState("");
  const filteredItems = medUseList.filter(
    (item) =>
      (item.code && item.code.toString().includes(search)) ||
      (item.name && item.name.toString().includes(search)) ||
      (item.type && item.type.toString().includes(search))
  );
  const [openFormMedicineUse, setOpenFormMedicineUse] = useState<{
    show: boolean;
    data: MedUse | null;
    saved: boolean
  }>({ show: false, data: null, saved: false });

  const navigate = useNavigate();
  const {profile } = useContexts();
  useEffect(() => {
    getMedUseList().catch(console.error);
    if((profile?.sub !== '0')){
      navigate("/setting")
    }
  }, []);

  useEffect(() => {
    if(openFormMedicineUse.saved){
      getMedUseList().catch(console.error);
      setOpenFormMedicineUse((prev) => ({
        ...prev,
        saved: false,
      }))
    }
}, [openFormMedicineUse.saved]);

  const getMedUseList = async () => {
    isLoad();
    setMedUseList(await apiService.medUseList({status: '1'}));
    isLoaded();
  };

  const removeMedUse = async (data: MedUse) => {
    await Swal.fire({
      title: `ต้องการลบรายการ  ${data.type} : ${data.name}`,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ปิด",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await apiService.removeMedUse(data.id);
        if (res.success === true) {
          getMedUseList().then();          
        }
        Alert(res.success, res.message);
      }
    });
  };

  return (
    <Container className={"mw-100"}>
        <h2>รายการวิธีใช้ยา</h2>
        <div  className="mb-2">ค้นหาข้อมูลได้จาก ประเภท และ การใช้หรือเวลา</div>  
        <div className="mb-2 row">
          <div className={"col-10"}>
          <input className={"form-control"} type="text" placeholder="ค้นหา.." onChange={(e) => setSearch(e.target.value)} />
          </div>
          <div className={"col-2 d-grid"}>
            <Button variant="success" onClick={() => setOpenFormMedicineUse({ show: true, data: null, saved: false })}><PlusCircle /> เพิ่มข้อมูล</Button>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>รหัส</th>
              <th>วิธีใช้/เวลาที่ใช้</th>
              <th>ประเภท</th>
              <th>แก้ไข</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((r) => (
              <tr>
                <td>{r.code}</td>
                <td>{r.name}</td>
                <td>{r.type}</td>
                <td className={"text-center"}>
                  <Button
                    size="sm"
                    onClick={() => setOpenFormMedicineUse({ show: true, data: r, saved: false })}
                  >
                    <Pen />
                  </Button>{" "}
                  <Button variant="danger" size="sm" onClick={() => removeMedUse(r).then()}>
                    <Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ModalMedicineUse
          show={openFormMedicineUse.show}
          data={openFormMedicineUse.data}
          handleClose={() =>
            setOpenFormMedicineUse((prev) => ({
              ...prev,
              show: false,
            }))
          }
          saved={() =>
            setOpenFormMedicineUse((prev) => ({
              ...prev,
              saved: true,
            }))}
        />
      </Container>
  );
}
