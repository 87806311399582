import React, { useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import { Pen, PlusCircle } from "react-bootstrap-icons";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import Select from "react-select";
import ModalPerson from "./modalPerson";
import DatePicker from "react-datepicker";
import th from "date-fns/locale/th";
import Swal from "sweetalert2";
import { format } from "date-fns";
import { Alert, HandleChange, OptionSelects } from '../functions/utils';
import {ChangeEvent, OptionSelect, OrderTransaction, Person, ScsChemo, ScsGem} from '../type';
import { apiService } from '../functions/api-service';
import {useContexts} from "../context/AppContext";

type Props = {
  show: boolean;
  showError: boolean;
  data: OrderTransaction | null;
  handleClose: () => void;
  saved: () => void;
};

export default function ModalOrderTransaction({ showError,show, data, handleClose, saved, }: Props) {
  const [IsNewTran, setIsNewTran] = useState(false);
  const [IsEdit, setIsEdit] = useState(false);
  const [dataForm, setDataForm] = useState<OrderTransaction | null>(null);
  const {profile } = useContexts();
  const [openFormPerson, setOpenFormPerson] = useState<{
    show: boolean;
    data: Person | null;
    saved: boolean;
    hn: string;
  }>({ show: false, data: null, saved: false, hn: "" });
  const [medList, setMedList] = useState<Array<OptionSelect>>([]);
  const [medUseList, setMedUseList] = useState<Array<OptionSelect>>([]);
  // const [scsList, setScsList] = useState<Array<ScsGem>>([]);
  const [deptList, setDeptList] = useState<Array<OptionSelect>>([]);
  const [treatmentList, setTreatmentList] = useState<Array<OptionSelect>>([]);
  const [scsGemList, setScsGemList] = useState<Array<OptionSelect>>([]);
  const [scsGemLists, setScsGemLists] = useState<Array<ScsGem>>([]);



  const handleChange = (e: ChangeEvent) => HandleChange(setDataForm, e);
  const handleSave = (closeForm = true) => {

    if (dataForm) {
      dataForm.dept = dataForm.dept
          ? dataForm.dept
          : (profile?.sub !== '0' ? profile?.sub : '');
    }

    console.log("handleSave dataForm", dataForm);

    if(dataForm?.scsGemId){
      dataForm.scsGemMacId = scsGemLists.find((e) => e.id == dataForm?.scsGemId)?.machine?.itemId ??'';
    }


    if(dataForm?.statusOrder){
      dataForm.statusOrder = showError ? '99' : dataForm.statusOrder;
    }
    if(dataForm?.perTime && dataForm?.med?.solvent && dataForm?.med?.strength ){
      // if(IsNewTran){
        var pull = (( parseFloat(dataForm?.med?.solvent) / parseFloat(dataForm?.med?.strength)) * parseFloat(dataForm?.perTime)).toFixed(4);
        if(dataForm?.med?.scsGem?.id){
          // dataForm.chemoPullGem = Math.round(Number(pull)).toString();
          dataForm.chemoPullGem = parseFloat(pull as string).toFixed(2);
        }else {
          // dataForm.chemoPull =  Math.round(Number(pull)).toString();
          dataForm.chemoPull =  parseFloat(pull as string).toFixed(2);
        }
      // }

    }else{
      Alert(false, 'กำหนดค่าการครั้งล่ะยาเคมี');
      return;
    }
    if(IsNewTran){
      Swal.fire( {
        title: `บันทึกข้อมูลสั่งยา ${dataForm.med.name}`,
        html: `<h5><p>ให้ยา <b>${amount.day}</b> วัน จำนวนวันละ ${amount.perDay} ขวด</p> 
        <p>วันที่เริ่มให้ยา <b>${format(new Date(amount.startDate), 'dd MMMM yyyy', {locale: th})}</b></p></h5>`,
        showCancelButton: true,
        confirmButtonText: 'บันทึกข้อมูล',
        cancelButtonText: 'ยกเลิก',
      } ).then(async result => {
        console.log('xxx-->',dataForm)
        if ( result.isConfirmed ) {
          const res = await apiService.saveOrderTran(dataForm, amount);
          if (res.success === true) {
            saved();
            setIsNewTran(false);
            if (closeForm) {
              handleClose();
              Alert(res.success, res.message);
            }
          } else {
            Alert(res.success, res.message);
          }
        }      
      });
    }else{
      Swal.fire( {
        title: `แก้ไขข้อมูลสั่งยา ${dataForm.med.name}`,
        html: `<h5><p>วันที่ให้ยา <b>${dataForm.orderDate ? format(new Date(dataForm.orderDate), 'dd MMMM yyyy', {locale: th}) : ''}</b></p>
        <p>TaskID: <b>${dataForm.orderID} </b></p></h5>`,
        showCancelButton: true,
        confirmButtonText: 'แก้ไขข้อมูล',
        cancelButtonText: 'ปิด',
      } ).then(async result => {
        if ( result.isConfirmed ) {

          console.log('editxxxxx-->',dataForm)
          const res = await apiService.saveOrderTran(dataForm, amount);
          if (res.success === true) {
            saved();
            setIsNewTran(false);
            if (closeForm) {
              handleClose();
              Alert(res.success, res.message);
            }
          } else {
            Alert(res.success, res.message);
          }
        }      
      });
    }       
  };
  const [amount, setAmount] = useState({perDay: data?.perDay?? 1, day: 1, startDate: new Date()});

  useEffect(() => {
    if (show) {
      console.log('dataForm', dataForm);
      console.log('data', data);
      setDataForm(data);
      setIsNewTran(data?.orderID == null ? true : false);
      setIsEdit(data?.orderID != null && profile?.site =='2' && profile.sub == '0' && data?.viewId != null ? false : true);
      getConst().then();
      getDept().then();
      getTreatment().then();
      if(data?.orderID == null && !data?.amount1){
        handleChange({ target: { name: "amount1", value: 1 } });
      }
    } else {
      setDataForm(null);
    }
  }, [show]);

  useEffect(() => {
    if (openFormPerson.saved) {
      //init person add new
      apiService.personList(openFormPerson.hn).then((res) => {
        if (res.length > 0) {
          setDataForm((prev) => ({
            ...prev,
            person: res[0],
          }));
        }
      });

      setOpenFormPerson((prev) => ({
        ...prev,
        saved: false,
        hn: "",
      }));
    }
  }, [openFormPerson.saved]);

  const getConst = async () => {
    setMedUseList(
      OptionSelects(
        await apiService.medUseList({ status: "1" }),
        "${name}",
        "id"
      )
    );
    setMedList(
      OptionSelects(
        await apiService.medList({ status: "1",productcat: "1" }),
        "${name}    (${strength} mg/ ${solvent}ml)",
        "code"
      )
    );

    setScsGemList(OptionSelects(
        await apiService.scsGemList({ status: "1" }),
        "${name}",
        "id"
    ));

    setScsGemLists(await apiService.scsGemList({ status: "1" }));
  };

  const getDept  = async () => {
    setDeptList(
        OptionSelects(
            await apiService.deptList({ status: "1" }),
            "${name}",
            "id"
        )
    );
  };

  const getTreatment  = async () => {
    setTreatmentList(
        OptionSelects(
            await apiService.treatmentList({ status: "1" }),
            "${name}",
            "name"
        )
    );
  };

  const loadOptions = debounce(
    async (inputText: string, callback: (person: OptionSelect[]) => void) => {
      let options: OptionSelect[] = [];
      const res = await apiService.personList(inputText);
      const list = res.filter(e => e.status);
      if (list.length > 0) {
        try {
          options = OptionSelects(
              list,
            "HN:${hn} - ${prefix} ${fullName}",
            "hn"
          );
        } catch (error: any) {
          Alert(false, error.message);
          return options;
        }
      }
      callback(options);
      return options;
    },
    350
  );

  const initScs = (data: ScsChemo | undefined): OptionSelect | null => {
    if(data){
      return {value: data.id, label: data?.scs?.name, data: data};
    }else{
      return null;
    }
  }
  const deptAuto = dataForm?.dept
      ? deptList.find((e) => e.data.name === dataForm.dept)
      : profile?.sub !== '0'
          ? deptList.find((e) => e.data.name === profile?.sub)
          : null;

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {(dataForm?.orderID == null)
              ? "เพิ่มรายการ"
              : "แก้รายการ : " + dataForm.orderID}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSave();
            }}
          >
            { !showError   && (<>
              <div className={"row "} style={{display: "-webkit-inline-box"}}>
                <Form.Group className="col-12 mb-1" controlId="person">
                  <Form.Label>ค้นหาผู้ป่วย</Form.Label>
                  <div className="row">
                  <div className={`${ dataForm?.person ? IsNewTran ? "col-10" : "col-12" : "col-11" } pe-0`} >
                      <AsyncSelect
                        isDisabled={!IsNewTran || !IsEdit}
                        required
                        id={"person"}
                        cacheOptions
                        // @ts-ignore
                        loadOptions={loadOptions}
                        defaultOptions
                        placeholder={"HN หรือ ชื่อ-สกุล"}
                        onChange={(e) =>{
                          handleChange({ target: { name: "person", value: e?.data },})
                          // handleChange({ target: { name: "dept", value: e?.data?.dept?.name },})
                        }
                        }
                        noOptionsMessage={() => "ไม่พบข้อมูล"}
                        value={
                          dataForm?.person
                            ? {
                                label: `HN:${dataForm.person.hn} - ${dataForm?.person?.fullName}`,
                                value: dataForm.person.hn,
                                data: dataForm.person,
                              }
                            : null
                        }
                      />
                    </div>
                    {IsNewTran && (
                      <>
                        <div className={"col-1 d-grid px-0"}>
                          <Button
                            size={"sm"}
                            variant={"success"}
                            onClick={() => {
                              setOpenFormPerson({ show: true, data: null, saved: false, hn: "", });
                            }}
                          >
                            <PlusCircle />
                          </Button>
                        </div>
                        {dataForm?.person && (
                          <div className={"col-1 d-grid px-0"}>
                            <Button
                              size={"sm"}
                              variant={"primary"}
                              onClick={() => {
                                setOpenFormPerson({
                                  show: true,
                                  data: dataForm?.person ? dataForm.person : null,
                                  saved: false,
                                  hn: dataForm?.person?.hn ?? '',
                                });
                              }}
                            >
                              <Pen />
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="col-12 mb-1" controlId="dept">
                  <Form.Label>แผนก  </Form.Label>
                  <Select
                      value={deptAuto}
                      isSearchable={true}
                      name="dept"
                      id="dept"
                      options={deptList}
                      onChange={(e) => handleChange({target: {name: 'dept', value: e?.data.name}})}
                  />
                </Form.Group>

                <Form.Group className="col-6 mb-3" controlId="treatmentName">
                  <Form.Label>ชื่อ Treatment</Form.Label>
                  <Select
                      value={dataForm?.treatmentName
                          ? treatmentList.find((e) => e.data.name == dataForm.treatmentName)
                          : null}
                      isSearchable={true}
                      name="treatmentName"
                      id="treatmentName"
                      options={treatmentList}
                      onChange={(e) => handleChange({target: {name: 'treatmentName', value: e?.data.name}})}
                  />
                  {/*<Form.Control*/}
                  {/*    type="text"*/}
                  {/*    placeholder="Treatment Name"*/}
                  {/*    onChange={handleChange}*/}
                  {/*    name={"treatmentName"}*/}
                  {/*    value={dataForm?.treatmentName}*/}
                  {/*/>*/}
                </Form.Group>

                <Form.Group className="col-3 mb-3" controlId="cycle">
                  <Form.Label>Cycle</Form.Label>
                  <Form.Control
                      type="text"
                      placeholder="Cycle"
                      onChange={handleChange}
                      name={"cycle"}
                      value={dataForm?.cycle}
                  />
                </Form.Group>

                <Form.Group className="col-3 mb-3" controlId="day">
                  <Form.Label>Day</Form.Label>
                  <Form.Control
                      type="text"
                      placeholder="Day"
                      onChange={handleChange}
                      name={"day"}
                      value={dataForm?.day}
                  />
                </Form.Group>

                <hr/>

                <Form.Group className="col-9 mb-1" controlId="med">
                  <Form.Label>รายการยาที่ 1</Form.Label>
                  <InputGroup>
                    <Select
                      isDisabled={!IsEdit}
                      required
                      value={
                        dataForm?.med?.code
                          ? medList.find((e) => e.value == dataForm?.med?.code)
                          : null
                      }
                      isSearchable={true}
                      name="med"
                      options={medList}
                      className={"form-control p-0"}
                      onChange={(e) =>{
                        handleChange({ target: { name: "med", value: e?.data } });
                        handleChange({ target: { name: "scsId", value: e?.data?.scsId ? e?.data.scsId : null } });
                        handleChange({ target: { name: "scsGemId", value: e?.data?.scsGem?.id ? e?.data?.scsGem?.id : null } });
                        handleChange({ target: { name: "method", value: e?.data?.method ? e?.data.method : null } });
                        handleChange({ target: { name: "time", value: e?.data?.time ? e?.data.time : null } });

                        handleChange({ target: { name: "dosage1", value: (e?.data.strength ? e?.data.strength + "mg" : "") + (parseFloat(e?.data.solvent) > 0 ? "/" + e?.data.solvent + "mL" : "/mL") } });
                      }}
                    />
                    {/*<InputGroup.Text>*/}
                    {/*  {dataForm?.dosage1 ? dataForm.dosage1 : "ขนาดยา"}*/}
                    {/*</InputGroup.Text>*/}
                  </InputGroup>
                </Form.Group>

                <Form.Group className="col-6 mb-1" style={{display:"none"}} controlId="dosage1" >
                  <Form.Label>Dosage</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={handleChange}
                    name={"dosage1"}
                    value={dataForm?.dosage1}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="col-3 mb-1" controlId="amount1">
                  <Form.Label>จำนวนขวดที่ 1</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    onChange={handleChange}
                    name={"amount1"}
                    value={dataForm?.amount1}
                    step='any'
                  />
                </Form.Group>

                <Form.Group className="col-9 mb-1" controlId="med2">
                  <Form.Label>รายการยาที่ 2</Form.Label>
                  <InputGroup>
                    <Select
                        value={
                          dataForm?.med2?.code
                              ? medList.find((e) => e.value == dataForm?.med2?.code)
                              : null
                        }
                        isSearchable={true}
                        name="med"
                        options={medList}
                        className={"form-control p-0"}
                        onChange={(e) =>{
                          handleChange({ target: { name: "med2", value: e?.data } });
                          handleChange({ target: { name: "dosage2", value: (e?.data.strength ? e?.data.strength + "mg" : "") + (parseFloat(e?.data.solvent) > 0 ? "/" + e?.data.solvent + "mL" : "/mL") } });
                        }}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="col-6 mb-1" style={{display:"none"}} controlId="dosage2">
                  <Form.Label>Dosage 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={handleChange}
                    name={"dosage2"}
                    value={dataForm?.dosage2}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="col-3 mb-1" controlId="amount2">
                  <Form.Label>จำนวนขวดที่ 2</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    onChange={handleChange}
                    name={"amount2"}
                    value={dataForm?.amount2}
                    step='any'
                  />
                </Form.Group>

              </div>

              <div className={"row row-cols-2"} style={{display:((dataForm?.orderID == null) ? "none" : "-webkit-inline-box")}}>

                <Form.Group className="mb-3" controlId="medOld">
                  <Form.Label>รายการยาคงเหลือ </Form.Label>
                  <InputGroup>
                    <Select
                        value={
                          dataForm?.medOld?.code
                              ? medList.find((e) => e.value == dataForm?.medOld?.code)
                              : null
                        }
                        isSearchable={true}
                        name="med"
                        options={medList}
                        className={"form-control p-0"}
                        onChange={(e) =>{
                          handleChange({ target: { name: "medOld", value: e?.data } });
                          // handleChange({ target: { name: "dosageOld", value: (e?.data.strength ? e?.data.strength + "mg" : "") + (parseFloat(e?.data.solvent) > 0 ? "/" + e?.data.solvent + "mL" : "/mL") } });
                        }}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="col-3" controlId="dosageOld">
                  <Form.Label>ปริมาตรยาเหลือ</Form.Label>
                  <InputGroup className="col-3">
                    <Form.Control
                        type="text"
                        placeholder="ปริมาตรยาเหลือ"
                        onChange={handleChange}
                        name={"dosageOld"}
                        value={dataForm?.dosageOld}
                    />
                    <InputGroup.Text>ml</InputGroup.Text>
                  </InputGroup>
                </Form.Group>


                <Form.Group className="col-3" controlId="amountOld">
                  <Form.Label>จำนวนขวดยาเหลือ</Form.Label>
                  <Form.Control
                      type="number"
                      placeholder=""
                      onChange={handleChange}
                      name={"amountOld"}
                      value={dataForm?.amountOld}
                      step='any'
                  />
                </Form.Group>

                {dataForm?.med?.scsGem?.id ? (
                  <Form.Group className="mb-3" controlId="chemoPullGem">
                  <Form.Label>chemo pull gem</Form.Label>
                  <Form.Control
                    disabled={!IsEdit}
                    type="number"
                    placeholder="chemo pull gem"
                    onChange={handleChange}
                    name={"chemoPullGem"}
                    value={dataForm?.chemoPullGem}
                    step='any'
                  />
                </Form.Group>
                ) : (
                  <Form.Group className="mb-3 bg-warning bg-opacity-25" controlId="chemoPull">
                  <Form.Label>chemo pull</Form.Label>
                  <Form.Control
                    disabled={!IsEdit}
                    type="number"
                    placeholder="chemo pull"
                    onChange={handleChange}
                    name={"chemoPull"}
                    value={dataForm?.chemoPull}
                    step='any'
                  />
                </Form.Group>
                )}

              </div >
              <hr />

              <div  className={"row row-cols-2"}>
              <Form.Group className="mb-3 bg-warning bg-opacity-25" controlId="perTime">
                <Form.Label>ครั้งละ (mg)</Form.Label>
                <Form.Control
                    required
                    type="number"
                    placeholder="ครั้งละ"
                    onChange={handleChange}
                    name={"perTime"}
                    value={dataForm?.perTime}
                    disabled={!IsEdit ||(!!(dataForm?.statusOrder && dataForm?.statusOrder > '1'))}
                    step='any'
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="scsId">
                <Form.Label>น้ำเกลือที่ใช้ผสม</Form.Label>
                <Select
                      isDisabled={!IsEdit}
                      value={dataForm?.scsId
                        ? initScs(dataForm?.med?.scsList.find((e) => e?.id == dataForm?.scsId))
                        : null}
                      isSearchable={true}
                      name="scsId"
                      options={dataForm?.med?.scsList && dataForm?.med?.scsList.length > 0 ?  dataForm?.med.scsList.filter((e) => e.status).map(r => ({value: r.id, label: r?.scs?.name, data: r})) : []}
                      onChange={(e) => handleChange({ target: { name: "scsId", value: e?.value ? e.value : null } })}
                      isClearable
                    />
                </Form.Group>

                {dataForm?.med?.scsGem?.id && (
                  <>
                    <Form.Group className="mb-3" controlId="scsGemId">
                    <Form.Label>น้ำเกลือที่ใช้ทำละลาย {dataForm?.scsGemMacId}</Form.Label>
                    {/*<Form.Control*/}
                    {/*  type="text"*/}
                    {/*  value={dataForm.med.scsGem.name}*/}
                    {/*  disabled*/}
                    {/*/>*/}
                    <Select
                        value={dataForm?.scsGemId
                            ? scsGemList.find((e) => e.value == dataForm?.scsGemId)
                            : null}
                        isSearchable={true}
                        name="scsGem"
                        id="scsGem"
                        options={scsGemList}
                        onChange={(e) =>{
                          handleChange({ target: { name: "scsGemId", value: e?.value ? e.value : null } })

                          // handleChange({ target: { name: "dosageOld", value: (e?.data.strength ? e?.data.strength + "mg" : "") + (parseFloat(e?.data.solvent) > 0 ? "/" + e?.data.solvent + "mL" : "/mL") } });
                        }}
                        isClearable
                    />

                  </Form.Group>

                  <Form.Group className="mb-3" controlId="scsGemUnitOld">
                    <Form.Label>ตัวทำละลายเหลือ <small className="text-danger">ปริมาตรตัวทำละลายคงเหลือ</small></Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="ระบุจำนวน"
                      onChange={handleChange}
                      name={"scsGemUnitOld"}
                      value={dataForm?.scsGemUnitOld}
                    />
                  </Form.Group>
                  </>
                )}

                <Form.Group className="mb-3" controlId="method">
                  <Form.Label>วิธีใช้ยา</Form.Label>
                  <Select
                      // isDisabled={!IsEdit}
                    value={
                      dataForm?.method?.id
                        ? medUseList.find((e) => e.value == dataForm?.method?.id)
                        : null
                    }
                    isSearchable={true}
                    name="method"
                    options={medUseList.filter(
                      (e) => e.data.type === "วิธีใช้ยา"
                    )}
                    onChange={(e) => handleChange({target: { name: "method", value: e?.data ? e.data : null }})}
                    isClearable
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="time">
                  <Form.Label>เวลาที่ใช้</Form.Label>
                  <Select
                    // isDisabled={!IsEdit}
                    value={
                      dataForm?.time?.id
                        ? medUseList.find((e) => e.value == dataForm?.time?.id)
                        : null
                    }
                    isSearchable={true}
                    name="time"
                    options={medUseList.filter((e) => e.data.type === "เวลาที่ใช้")}
                    onChange={(e) =>handleChange({ target: { name: "time", value: e?.data ? e.data : null } })}
                    isClearable
                  />
                </Form.Group>
              </div>
              <hr />

              {IsNewTran ? (
                <div className="row row-cols-2">
              <Form.Group className="mb-3 bg-warning bg-opacity-25" controlId="day">
                  <Form.Label>จำนวนวันที่ให้ยา</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="ระบุจำนวน"
                    onChange={(e) => HandleChange(setAmount, e)}
                    name={"day"}
                    value={amount.day}
                    step='any'
                  />
                </Form.Group>

                <Form.Group className="mb-3 bg-warning bg-opacity-25" controlId="perDay">
                  <Form.Label>จำนวนขวดต่อวัน</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="ระบุจำนวน"
                    onChange={(e) => HandleChange(setAmount, e)}
                    name={"perDay"}
                    value={amount.perDay}
                    step='any'
                  />
                </Form.Group>

                <Form.Group className="mb-3 bg-warning bg-opacity-25" controlId="startDate">
                  <Form.Label>วันที่เริ่มให้ยา</Form.Label>
                  <DatePicker
                    required
                    name={"startDate"}
                    selected={amount.startDate}
                    onChange={(date: Date) =>
                      HandleChange(setAmount, { target: { name: "startDate", value: date } })
                    }
                    locale={th}
                    dateFormat={"dd-MM-yyyy"}
                    className={"form-control"}
                  />
                </Form.Group>
              </div>
              ) : (
                <div className="row row-cols-2">
                  <Form.Group className="col-3 mb-3 bg-warning bg-opacity-25" controlId="orderDate">
                    <Form.Label>วันที่ให้ยา</Form.Label>
                    <DatePicker
                      required
                      name={"orderDate"}
                      selected={dataForm?.orderDate ? new Date(dataForm.orderDate) : null}
                      onChange={(date: Date) =>
                        handleChange({ target: { name: "orderDate", value: date } })
                      }
                      locale={th}
                      dateFormat={"dd-MM-yyyy"}
                      className={"form-control"}
                    />
                  </Form.Group>

                  <Form.Group className="col-4 mb-1" controlId="noDay">
                    <Form.Label>Day (วันที่ผสมยา)</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder=""
                        onChange={handleChange}
                        name={"noDay"}
                        value={dataForm?.noDay}
                        step='any'
                    />
                  </Form.Group>

                  <Form.Group className="col-3 mb-1" controlId="noInDay">
                    <Form.Label>ขวดที่ </Form.Label>
                    <Form.Control
                        type="number"
                        placeholder=""
                        onChange={handleChange}
                        name={"noInDay"}
                        value={dataForm?.noInDay}
                        step='any'
                    />
                  </Form.Group>

                  <Form.Group className="col-3 mb-3 " controlId="mfgDate">
                    <Form.Label>MFG Time :</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="MFG Time"
                        onChange={handleChange}
                        name={"mfgDate"}
                        value={dataForm?.mfgDate}
                        step='any'
                    />
                  </Form.Group>
              </div>
              )}
            </>
            )}

            { showError  &&(
                <>
                <hr/>
            <Form.Group className="col-12 mb-3" controlId="dataForm.orderID">
              <Form.Label>Error</Form.Label>
              <Form.Control
                  name="error"
                  id="error"
                  value={dataForm?.error}
                  onChange={handleChange}
                  as="textarea" rows={3} />
            </Form.Group>
                </>
        )}

            <hr/>

            <div className="w-100 text-end">
              <Button variant="secondary" size="lg" onClick={handleClose}>
                ปิด
              </Button>{" "}
              <Button variant="primary" type="submit" size="lg">
                บันทึก
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ModalPerson
        show={openFormPerson.show}
        data={openFormPerson.data}
        handleClose={() =>
          setOpenFormPerson((prev) => ({
            ...prev,
            show: false,
          }))
        }
        saved={() =>
          setOpenFormPerson((prev) => ({
            ...prev,
            saved: true,
          }))
        }
        setHn={(hn: string) =>
          setOpenFormPerson((prev) => ({
            ...prev,
            hn: hn,
          }))
        }
      />
    </>
  );
}
