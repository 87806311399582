import { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { Pen, PlusCircle, Trash } from "react-bootstrap-icons";
import { apiService } from '../../functions/api-service';
import ModalScsGem from '../../components/modalScsGem';
import Swal from 'sweetalert2';
import { Alert } from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import { ScsGem } from "../../type";
import {useNavigate} from "react-router-dom";

export default function SettingMedicineScsGem() {
  const { isLoad, isLoaded } = useContexts();
  const [ScsGemList, setScsGemList] = useState<Array<ScsGem>>([]);
  const [search, setSearch] = useState("");
  const filteredItems = ScsGemList.filter((item) => item.name && item.name.toString().includes(search));
  const [openFormScsGem, setOpenFormScsGem] = useState<{
    show: boolean;
    data: ScsGem | null;
    saved: boolean
  }>({ show: false, data: null, saved: false });

    const navigate = useNavigate();
    const {profile } = useContexts();
    useEffect(() => {
        getMedScsgem().catch(console.error);
        if((profile?.sub !== '0')){
            navigate("/setting")
        }
    }, []);


  useEffect(() => {
    if(openFormScsGem.saved){
      getMedScsgem().catch(console.error);
      setOpenFormScsGem((prev) => ({
        ...prev,
        saved: false,
      }))
    }
}, [openFormScsGem.saved]);

  const getMedScsgem = async () => {
    isLoad();
    setScsGemList(await apiService.scsGemList({status: '1'}));
    isLoaded();
  };

  const removeScsGem = async (data: ScsGem) => {
    await Swal.fire({
      title: `ต้องการลบ ${data.name}`,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ปิด",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await apiService.removeScsGem(data.id);
        if (res.success === true) {
          getMedScsgem().then();          
        }
        Alert(res.success, res.message)
      }
    });
  };

  return (
    <Container className={"mw-100"}>
        <h2>รายการน้ำเกลือทำละลาย</h2>
        <div  className="mb-2">ค้นหาข้อมูลได้จาก ชื่อ ตัวทำละลาย</div>  
        <div className="mb-2 row">
          <div className={"col-10"}>
          <input className={"form-control"} type="text" placeholder="ค้นหา.." onChange={(e) => setSearch(e.target.value)} />
          </div>
          <div className={"col-2 d-grid"}>
            <Button variant="success" onClick={() => setOpenFormScsGem({ show: true, data: null, saved: false })}><PlusCircle /> เพิ่มข้อมูล</Button>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>รหัส</th>
              <th>ตัวทำละลาย</th>
              <th>Machine</th>
              <th>แก้ไข</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((r) => (
              <tr>
                <td>{r.id}</td>
                <td>{r.name}</td>
                <td>{r.machine?.itemId}{r.machine?.itemId ?' : ':''}{r.machine?.fullName??''}</td>
                <td className={"text-center"}>
                  <Button
                    size="sm"
                    onClick={() => setOpenFormScsGem({ show: true, data: r, saved: false })}
                  >
                    <Pen />
                  </Button>{" "}
                  <Button variant="danger" size="sm" onClick={() => removeScsGem(r).then()}>
                    <Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ModalScsGem
          show={openFormScsGem.show}
          data={openFormScsGem.data}
          handleClose={() =>
            setOpenFormScsGem((prev) => ({
              ...prev,
              show: false,
            }))
          }
          saved={() =>
            setOpenFormScsGem((prev) => ({
              ...prev,
              saved: true,
            }))}
        />
      </Container>
  );
}
