import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-th-sarabun-new/css/th-sarabun-new.css";
import { AppProvider, useContexts } from "./context/AppContext";
import Login from "./pages/login";
import { RequireAuth } from "./functions/utils";
import Home from "./pages/home";
import Layout from "./components/layout";
import NotFound from "./pages/NotFound";
import Setting from "./pages/settings/setting";
import SettingMedicine from "./pages/settings/medicine";
import SettingMedicineScsChemo from "./pages/settings/scs-chemo";
import SettingMedicineScsGem from "./pages/settings/scs-gem";
import SettingMedicineUse from "./pages/settings/medicine-use";
import SettingPrefix from "./pages/settings/prefix";
import SettingPerson from "./pages/settings/person";
import SettingUser from "./pages/settings/user";
import SettingDept from "./pages/settings/dept";
import Syn from "./pages/syn/syn";
import SynMedicine from "./pages/syn/med";
import SynReport from "./pages/syn/report";
import SynReport1 from "./pages/syn/report1";
import SynReport2 from "./pages/syn/report2";
import SynReport3 from "./pages/syn/report3";
import SettingTreatment from "./pages/settings/treatment";
import SynMedicineView from "./pages/syn/medView";
import Api from "./pages/api";

function AppRoutes() {
  const baseURL = process.env.PUBLIC_URL;
  return useRoutes([
    { path: baseURL+"/api/v1.0", element: <Api /> },
    { path: baseURL+"/login", element: <Login /> },
    { path: baseURL+"/", element: <Layout><Home /></Layout> },
    { path: baseURL+"/setting", element: <Layout><Setting /></Layout> },
    { path: baseURL+"/setting/medicine", element: <Layout><SettingMedicine /></Layout> },
    { path: baseURL+"/setting/scs-chemo", element: <Layout><SettingMedicineScsChemo /></Layout> },
    { path: baseURL+"/setting/scs-gem", element: <Layout><SettingMedicineScsGem /></Layout> },
    { path: baseURL+"/setting/medicine-use", element: <Layout><SettingMedicineUse /></Layout> },
    { path: baseURL+"/setting/prefix", element: <Layout><SettingPrefix /></Layout> },
    { path: baseURL+"/setting/person", element: <Layout><SettingPerson /></Layout> },
    { path: baseURL+"/setting/dept", element: <Layout><SettingDept /></Layout> },
    { path: baseURL+"/setting/treatment", element: <Layout><SettingTreatment /></Layout> },
    { path: baseURL+"/setting/user", element: <Layout><SettingUser /></Layout> },
    { path: baseURL+"/syn", element: <Layout><Syn /></Layout> },
    { path: baseURL+"/syn/medicine", element: <Layout><SynMedicine /></Layout> },
    { path: baseURL+"/syn/medicineView", element: <Layout><SynMedicineView /></Layout> },
    { path: baseURL+"/syn/report", element: <Layout><SynReport /></Layout> },
    { path: baseURL+"/syn/report1", element: <Layout><SynReport1 /></Layout> },
    { path: baseURL+"/syn/report2", element: <Layout><SynReport2 /></Layout> },
    { path: baseURL+"/syn/report3", element: <Layout><SynReport3 /></Layout> },
    {path: baseURL+'*', element: <Layout><NotFound/></Layout>}
  ]);
}

function App() {
  return (
    <Router>
      <AppProvider >
        <AppRoutes />
      </AppProvider>
    </Router>
  );
}

export default App;
