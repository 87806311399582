import React, {useEffect, useRef, useState} from 'react';
import ReactToPrint from 'react-to-print';
import {PrinterFill,} from 'react-bootstrap-icons';
import {Button, Col, Row} from 'react-bootstrap';
import {calSyring} from '../functions/utils';
import { format } from 'date-fns';
import th from 'date-fns/locale/th';
import {QRCodeSVG} from 'qrcode.react';
import {apiService} from '../functions/api-service';
import { OrderTransaction } from '../type';
// var QrCodeX = require('qrcode.react').default

type Props = {
    data: OrderTransaction | null;
    orderQrTranList:Array<OrderTransaction> | null;
    contentType:string
};


const setPage = (r:any,orderList:any,contentType:any) =>{
    let list:any;
    let data:any;
    if(contentType == 'Summary') {
        list = orderList.filter((e: { orderDate: any; }) => (format(e.orderDate, "yyyy-MM-dd")) == r);
        data = list[0];
        if (data?.orderDate) {
            data.orderDate = new Date(data.orderDate);
        }
    }else {
        list = orderList.filter((e: { person: { hn: any; }; }) => (e.person.hn) == r);
        data = list[0];
        if (data?.orderDate) {
            data.orderDate = new Date(data.orderDate);
        }
    }
    let i = 0;
    return (
        <Row className={"thsarabunnew pagebreak px-1"} style={{margin: "5px", fontSize: "16px", fontWeight: "500"}}>
            <Col className={"col-12"}> &nbsp;</Col><Col className={"col-12 mb-2"}> &nbsp;</Col>
            <Col className={"col-6 fw-bold"}>
                วันที่ {data?.orderDate && (format(data?.orderDate, "dd MMM", {locale: th}) + ' ' +
                    (Number(format(data?.orderDate, "Y")) + 543).toString())}
            </Col>
            <Col className={"col-6  fw-bold"} style={{textAlign: "right"}}>{data?.dept}</Col>

            <Col className={"col-12  fw-bold"}> HN {data?.person?.hn + ' ' + data?.person?.fullName}</Col>

            {list && list.map((x: any) => {
                let pull= x.chemoPull != '0.0' ? x.chemoPull: x.chemoPullGem;
                let nss = (x?.scsId && x?.med && x?.med?.scsList && x?.med?.scsList.length > 0 && x.med.scsList.find((e:any) => (x.scsId && e.id === x.scsId))?.scs?.name) ??''
                i++;
                return ( <>


                    <Col className={"col-12 fw-bold"} style={{fontSize: "14px",paddingLeft:'20px'}}>
                        {"- "+ x?.med?.name}
                        {/*{' (' + x?.med?.strength + 'mg/' + x?.med?.solvent + 'ml)'}*/}
                        {/*{' ' + x.perTime + 'mg (' + pull + 'ml) + ' + nss }*/}
                        {' ' + x.perTime + 'mg in ' + nss }

                        {/* + " " + (x.method?.name ?? "")  { (x.time?.name ?? "")}*/}

                    </Col>
                    {( (i!=0 && i%4==0 && list.length > 4) ?
                        <><Col className={"pagebreak col-12"}> &nbsp;</Col><Col className={"col-12 mb-4"}> &nbsp;</Col></>
                    : '') }
                </>)
            })}

        </Row>
    );
}

class ContentGroupChemo extends React.Component<Props>  {

    render() {
        const {data, orderQrTranList} = this.props
        let contentType: string;


        contentType = this.props.contentType
        let orderList:Array<OrderTransaction> =[];

        if(data?.orderDate){
            data.orderDate = new Date(data.orderDate);
        }
        
        // let colDate:any;
        // let rowDrug:any;
        // let colBankDate :any;
        // let dateList: string[] =[];
        // if(orderQrTranList && orderQrTranList.length > 0){
        //     //set col Date
        //     orderList = orderQrTranList.filter(e => e.person?.hn == data?.person?.hn );
        //     let gDate:string = 'x'
        //     colDate = orderList.map((r, i) => {
        //         if(r?.orderDate){
        //             r.orderDate = new Date(r.orderDate);
        //         }
        //
        //         if(r.orderDate && (gDate && gDate != (r.orderDate).toString()))
        //         {
        //             dateList.push(format(r?.orderDate, "dd"));
        //             gDate = r.orderDate ? (r.orderDate).toString() : '';
        //             return (<Col className={"col-1 "}
        //                         style={{padding: "0",
        //                             borderTop:" 1px solid #000",
        //                             borderRight:" 1px solid #000",
        //             }}>
        //                 {r?.orderDate ? format(r?.orderDate, "dd") : ''}
        //             </Col>)
        //         }
        //
        //     });
        //
        //
        //
        //     // set row Drug
        //     let medCode:string = 'x'
        //     let medDay:any
        //     orderList.sort((a, b) =>
        //         ((a.med ? a.med.code : '') > (b.med ? b.med.code : '')) ? 1 : -1)
        //     orderList.sort((a, b) =>
        //         ((a.noInDay ? a.noInDay : 0) > (b.noInDay ? b.noInDay : 0)) ? 1 : -1)
        //
        //     rowDrug = orderList.map((r, i) => {
        //         if(r?.orderDate){
        //             r.orderDate = new Date(r.orderDate);
        //         }
        //
        //
        //             if(((medCode != (r.med && r.med.code+'-'+r.noInDay)))) {
        //                 // @ts-ignore
        //
        //                 medCode = (r.med.code+'-'+r.noInDay)
        //
        //                 // gDate ='x'
        //                 medDay = dateList.map(j  =>{
        //                     let checkX = orderList.filter(e => (e.med?.code == r.med?.code && e?.orderDate && format(e?.orderDate, "dd")  == j));
        //                     // if(r?.orderDate && format(r?.orderDate, "dd") == j){
        //                         return (<Col className={"col-1"}
        //                                     style={{padding: "0",
        //                                         borderLeft:" 1px solid #000"}}>
        //                             {checkX.length > 0 ? ' x ' :' '}
        //                         </Col>)
        //                     // }
        //
        //                 })
        //
        //                 let nes = r.med?.scsList.find(e=> e.id === r.scsId)?.scs?.name;
        //                 return (<Row className={"col-12 border border-dark center "}
        //                             style={{padding: "0",margin:"0"}}>
        //                         <Col style={{padding: "0",textAlign:"left" }} >
        //                             {' '}({r.chemoPull??r.chemoPullGem}){r?.med?.name}{nes ? ' + ':''}{nes}
        //                         </Col>
        //                     {medDay}
        //                 </Row>
        //             )
        //             }
        //
        //     })
        //
        //     // Set blank col
        //     colBankDate = dateList.map((r, i) => {
        //     return (<Col className={"col-1 "}
        //                 style={{padding: "0",
        //                     // borderTop:" 1px solid #000",
        //                     borderRight:" 1px solid #000",
        //                     borderBottom:" 1px solid #000",
        //                 }}>{' '}</Col>)
        //     })
        // }
        // return (<Row id={data.orderID} className={"thsarabunnew pagebreak"}
        //              style={{textAlign:"center", height:'auto', overflow:"visible",
        //                  margin: "45.35px 3.7px 3.7px 3.7px",fontSize:"10px"}}>
        //     <Col className={"col-8 px-1"} style={{textAlign:"left"}} >
        //         {data.person?.hn+' '+data.person?.fullName}
        //     </Col>
        //     <Col className={"col-4 px-1"}style={{fontSize:"12px",textAlign:"right"}}>
        //         {data?.orderDate && (format(data?.orderDate,  " MMM", {locale: th}) + ' ' + (Number(format(data?.orderDate,  "Y")) + 543).toString())}
        //     </Col>
        //
        //     <Col className={""}
        //          style={{padding:"0",
        //         borderLeft:" 1px solid #000",
        //         borderTop:" 1px solid #000",
        //         borderRight:" 1px solid #000",}}>
        //         รายการ
        //     </Col>
        //     {colDate}
        //
        //     <Row></Row>
        //     {rowDrug}
        //     <Row></Row>
        //     <Col className={""} style={{
        //         textAlign:"left",
        //         borderLeft:" 1px solid #000",
        //         borderBottom:" 1px solid #000",
        //         borderRight:" 1px solid #000",}} >
        //         ผู้เช็คยา
        //     </Col>{colBankDate}
        //
        //     <Row></Row>
        //
        //     <Col className={""} style={{
        //         textAlign:"left",
        //         borderLeft:" 1px solid #000",
        //         borderBottom:" 1px solid #000",
        //         borderRight:" 1px solid #000",}} >
        //         ผู้จ่ายยา
        //     </Col>{colBankDate}
        //
        // </Row>)
        if(orderQrTranList && orderQrTranList.length > 0) {
            //set col Date
            if(contentType == 'Summary' && data != null ) {
                orderList = orderQrTranList.filter(e => e.person?.hn == data?.person?.hn);

                orderList.sort((a, b) =>
                    ((a.orderDate ? a.orderDate : '') > (b.orderDate ? b.orderDate : '')) ? 1 : -1)
            }else{
                orderList = orderQrTranList;
                orderList.sort((a, b) =>
                    ((a?.person?.hn ? a?.person?.hn : '') < (b?.person?.hn ? b?.person?.hn : '')) ? 1 : -1)
            }


            let gDate:string | undefined = 'x';
            const orderDateList: string[] =[];
            if(contentType == 'Summary' ) {
                orderList.map((r, i) => {
                    if (r?.orderDate) {
                        r.orderDate = new Date(r.orderDate);
                    }

                    if (r.orderDate && (gDate && gDate != (r.orderDate).toString())) {
                        orderDateList.push(format(r?.orderDate, "yyyy-MM-dd"));
                        gDate = r.orderDate ? (r.orderDate).toString() : '';
                    }

                });
            }else {
                orderList.map((r, i) => {

                    if (r.person?.hn && (gDate && gDate != (r.person?.hn).toString())) {
                        orderDateList.push(r?.person?.hn as string);
                        gDate = r?.person?.hn;
                    }

                });

            }
console.log(contentType)
console.log(orderList)
console.log('list --->  ',orderDateList)
            return (<div className="print-container" style={{minHeight: "", margin: "", padding: "0"}}>
                {orderDateList && orderDateList.map((r: any) => (

                        <>
                            {setPage(r, orderList,contentType)}
                        </>
                ))}
            </div>);
        }else {
            return (<div></div>)
        }
    }
}

let BtnPrintSummaryChemo = ({ data,orderQrTranList,contentType }: Props) => {
    const componentRef = useRef(null);
    //TODO result not real time

    // const [orderQrTranList,setOrderTranList] = useState<Array<OrderTransaction>>([]);
    // const getOrderTranList = async (orderDate: Date) => {
    //     console.log('getOrderTranList')
    //     setOrderTranList(
    //         await apiService.orderTranList({createAt: format(new Date(orderDate), "yyyy-MM-dd")})
    //
    //     )
    //     console.log('summar-->',orderQrTranList)
    // };
    // useEffect(() => {
    //     if(data?.orderDate){
    //         getOrderTranList(data?.orderDate).catch(console.error)
    //     }
    // },[])
    return (
        <span className={""}>
            <ReactToPrint
                trigger={() => <Button style={{marginLeft: "2px"}} variant="warning"
                                       size={contentType == 'SummaryByDate' ? "lg":"sm"}>
                    <PrinterFill/> พิมพ์ตารางสรุป </Button>}
                content={() => componentRef.current}
                pageStyle={`@media print {
                    @page {
                      size:  3.54in 2.75in;               
                    }
                    .pagebreak {
                        clear: both;
                        page-break-after: always;
                    }
                  }`}
            />
            <div className={"hide"} style={{display:"none"}}>
            {/*<div>*/}
                <ContentGroupChemo ref={componentRef} data={data}  orderQrTranList={orderQrTranList} contentType={contentType}/>
            </div>
        </span>
    );
};

export default BtnPrintSummaryChemo;