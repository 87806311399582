import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { apiService } from '../functions/api-service';
import Select from "react-select";
import { ChangeEvent, OptionSelect, Person } from '../type';
import { Alert, HandleChange, OptionSelects } from '../functions/utils';
import {useContexts} from "../context/AppContext";

type Props = {
  show: boolean;
  data: Person | null;
  handleClose: () => void;
  saved: () => void;
  setHn: (hn: string) => void
};

export default function ModalPerson ( { show, data, handleClose, saved, setHn }: Props ) {
  let IsNew = true;
  const [dataForm, setDataForm] = useState<Person | null>( null );
  const handleChange = (e: ChangeEvent) => HandleChange(setDataForm, e);
  const [prefixList, setPrefixList] = useState<Array<OptionSelect>>([]);
  const [deptList, setDeptList] = useState<Array<OptionSelect>>([]);
  const {profile } = useContexts();

  const handleSave = async () => {
    console.log( 'dataForm', dataForm )  
    if(dataForm){
      dataForm.dept = (profile?.sub != '0' ?  deptList.find((e) => e.data.name == profile?.sub)?.value : null)
      const res = await apiService.savePerson( dataForm );
      if ( res.success === true && dataForm?.hn) {
        setHn(dataForm.hn);
        saved();
        handleClose();
      }
      Alert(res.success, res.message);
    }else{
      Alert(false, 'ไม่พบข้อมูลที่จะบันทึก');
    }
  }

  useEffect(() => {
    getPrefix().then()
    const fullName = (dataForm?.prefix ? prefixList.find((e) => e.value == dataForm?.prefix?.id)?.data.name : '')
    +' '+ (dataForm?.firstName ? dataForm.firstName : '') + ' ' + (dataForm?.lastName ? dataForm.lastName : '')

    setDataForm((prev: any) => ({
      ...prev,
      fullName,
    }));    
  }, [dataForm?.firstName, dataForm?.lastName, dataForm?.prefix])

  useEffect(() => {
    if ( show ) {
      setDataForm( data );
      console.log( 'data', data );
      IsNew = !data?.hn;
      getPrefix().then()
      getDept().then()
    } else {
      setDataForm( null );
    }
  }, [show]);

  const getPrefix  = async () => {
    setPrefixList(
      OptionSelects(
        await apiService.prefixList({ status: "1" }),
        "${name}",
        "id"
      )
    );
  };

  const getDept  = async () => {
    setDeptList(
      OptionSelects(
        await apiService.deptList({ status: "1" }),
        "${name}",
        "id"
      )
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{!dataForm?.fullName ? `เพิ่มผู้ป่วย` : `แก้ไขผู้ป่วย: ${dataForm?.fullName}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className={"row row-cols"} onSubmit={( e ) => {
            e.preventDefault();
            handleSave().then();
          }}>
            <Form.Group className="mb-3" controlId="hn">
                <Form.Label>HN</Form.Label>
                <Form.Control
                required
                type="text"
                placeholder="HN"
                onChange={handleChange}
                name={"hn"}
                value={dataForm?.hn}
              />
              </Form.Group>  
            <Form.Group className="mb-3" controlId="prefix">
                <Form.Label>คำนำหน้า</Form.Label>
                <Select
                    required                  
                    value={dataForm?.prefix?.id 
                      ? prefixList.find((e) => e.value == dataForm.prefix.id)
                      : null}
                    isSearchable={true}
                    name="method"
                    id="method"
                    options={prefixList}
                    onChange={(e) => handleChange({target: {name: 'prefix', value: e?.data}})}
                  />
              </Form.Group>            
            <Form.Group className="mb-3" controlId="firstName">
              <Form.Label>ชื่อ</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="ชื่อผู้ป่วย"
                onChange={handleChange}
                name={"firstName"}
                value={dataForm?.firstName}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="lastName">
              <Form.Label>นามสกุล</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="นามสกุล"
                onChange={handleChange}
                name={"lastName"}
                value={dataForm?.lastName}
              />
            </Form.Group>

            {/*<Form.Group className="mb-3" controlId="prefix">*/}
            {/*  <Form.Label>แผนก</Form.Label>*/}
            {/*  <Select*/}
            {/*      value={dataForm?.dept?.id*/}
            {/*          ? deptList.find((e) => e.value == dataForm.dept.id)*/}
            {/*          : null}*/}
            {/*      isSearchable={true}*/}
            {/*      name="method"*/}
            {/*      id="method"*/}
            {/*      options={deptList}*/}
            {/*      onChange={(e) => handleChange({target: {name: 'dept', value: e?.data}})}*/}
            {/*  />*/}
            {/*</Form.Group>*/}

            <Form.Group className="mb-3" controlId="fullName">
              <Form.Label>ชื่อ - สกุล</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="ชื่อ - สกุล"
                name={"fullName"}
                value={dataForm?.fullName}
                readOnly={true}
                disabled={true}
              />
            </Form.Group>
            
            <div className="w-100 text-end">
              <Button variant="secondary" size="lg" onClick={handleClose}>
                ปิด
              </Button>{" "}
              <Button variant="primary" type="submit" size="lg">
                บันทึก
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>    
    </>
  );
}
