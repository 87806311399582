import {useEffect, useState} from "react";
import {Button, Modal, Form, InputGroup, Table} from "react-bootstrap";
import {Pen, PlusCircle, Trash} from "react-bootstrap-icons";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import Select from "react-select";
import ModalPerson from "./modalPerson";
import DatePicker from "react-datepicker";
import th from "date-fns/locale/th";
import Swal from "sweetalert2";
import {format} from "date-fns";
import {Alert, HandleChange, OptionSelects} from '../functions/utils';
import {ChangeEvent, OptionSelect, OrderTransaction, Person, ScsChemo} from '../type';
import {apiService} from '../functions/api-service';
import {useContexts} from "../context/AppContext";
import BtnPrintQrChemo from "./printQrChemo";
import BtnPrintQrGroupChemo from "./printGroupQrChemo";
import BtnPrintSummaryChemo from "./printSummaryChemo";

type Props = {
    show: boolean;
    data: any;
    handleClose: () => void;
    saved: () => void;
};

export default function ModalPrintQrByDate ({show, data, handleClose, saved,}: Props) {
    const [orderDate, setOrderDate] = useState(new Date());

    const { profile } = useContexts();
    useEffect(() => {
        if (show) {
            console.log('dataForm', orderDate);
            console.log('data', data);
            getOrderTranList(orderDate).then()
        } else {
            setOrderDate(new Date());
        }
    }, [show]);


    const [orderTranList, setOrderTranList] = useState<Array<OrderTransaction>>([]);
    const getOrderTranList = async (date: string | number | Date) => {
        setOrderTranList(
            await apiService.orderTranList({orderDate: format(new Date(date), "yyyy-MM-dd")})
        );
    };
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
                // size={"sm"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {"พิมพ์ QR ด้วยวันที่"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form
                    >
                        <div className={"row row-cols-1"}>
                            <div className={"col-12"}>{data?.orderDate??''}
                                <label htmlFor="orderDate" className={"form-label"}>
                                    วันที่สั่ง
                                </label>
                                <DatePicker
                                    id={"orderDate"}
                                    name={"orderDate"}
                                    selected={orderDate}
                                    onChange={(date: Date) => {
                                        setOrderDate(date);
                                        getOrderTranList(date).then()
                                    }
                                    }
                                    locale={th}
                                    dateFormat={"dd-MM-yyyy"}
                                    className={"form-control"}
                                />
                            </div>


                        </div>



                        <hr/>

                        <div className="w-100 text-end">
                            <Button variant="secondary" size="lg" onClick={handleClose}>
                                ปิด
                            </Button>{" "}
                            <BtnPrintQrGroupChemo data={null} site={profile?.site} orderQrTranList={orderTranList} contentType={'QrGroupByDate'}/>

                            <BtnPrintSummaryChemo data={null} orderQrTranList={orderTranList}  contentType={'SummaryByDate'}/>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
