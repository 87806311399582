import { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { Pen, PlusCircle, Trash } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import { apiService } from '../../functions/api-service';
import { Alert } from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import ModalScsChemo from '../../components/modalScsChemo';
import { ScsChemo } from "../../type";
import {useNavigate} from "react-router-dom";

export default function SettingMedicineScsChemo() {
  const { isLoad, isLoaded } = useContexts();
  const [scsChemoList, setScsChemoList] = useState<Array<ScsChemo>>([]);
  const [search, setSearch] = useState("");
  const filteredItems = scsChemoList.filter(
    (item) =>
      ((item.med?.name && item.med.name.toString().includes(search)) ||
      (item.scs?.name && item.scs.name.toString().includes(search)))
  );
  const [openFormScsChemo, setOpenFormScsChemo] = useState<{ show: boolean; data: ScsChemo | null; saved: boolean; }>( { show: false, data: null, saved: false } );

  const navigate = useNavigate();
  const {profile } = useContexts();
  useEffect(() => {
    getScsChemoList().catch(console.error);
    if((profile?.sub !== '0')){
      navigate("/setting")
    }
  }, []);

  useEffect(() => {
    if (openFormScsChemo.saved) {
      getScsChemoList().catch(console.error);
      setOpenFormScsChemo((prev) => ({
        ...prev,
        saved: false,
      }));
    }
  }, [openFormScsChemo.saved]);

  const getScsChemoList = async () => {
    isLoad();
    setScsChemoList(await apiService.scsChemoList({status: '1'}));
    isLoaded();
  };

  const removeScsChemo = async (data: ScsChemo) => {
    await Swal.fire({
      title: "ต้องการลบน้ำเกลือ " + data?.scs?.name,
      html: `<h5>ใช้ผสมยา ${data.med.name}</h5>`,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ปิด",
    }).then(async (result) => {
      if (result.isConfirmed && data.id) {
        const res = await apiService.removeScsChemo(data.id);
        if (res.success === true) {
          getScsChemoList().then();          
        }
        Alert(res.success, res.message);
      }
    });
  };

  return (
    <Container className={"mw-100"}>
        <h2>รายการน้ำเกลือสำหรับผสมยา</h2>
        <div className="mb-2">
          ค้นหาข้อมูลได้จาก ชื่อยา หรือ ชื่อน้ำเกลือ
        </div>
        <div className="mb-2 row">
          <div className={"col-10"}>
            <input
              className={"form-control"}
              type="text"
              placeholder="ค้นหา.."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className={"col-2 d-grid"}>
            <Button
              variant="success"
              onClick={() =>
                setOpenFormScsChemo({ show: true, data: null, saved: false })
              }
            >
              <PlusCircle /> เพิ่มยา
            </Button>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ชื่อยา</th>
              <th>ชื่อน้ำเหลือ</th>
              <th>เวลาคงตัว</th>
              <th>อุณหภูมิเก็บรักษา</th>
              <th>แก้ไข</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((r) => (
              <tr>
                <td>{r.med?.name}</td>
                <td>{r?.scs?.name}</td>
                <td>{r.stableTime + ' ' + r.stableType}</td>
                <td>{r.temperature}&nbsp;&nbsp; °C &nbsp;&nbsp;</td>
                <td className={"text-center"}>
                  <Button
                    size="sm"
                    onClick={() =>
                      setOpenFormScsChemo({ show: true, data: r, saved: false })
                    }
                  >
                    <Pen />
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => removeScsChemo(r).then()}
                  >
                    <Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ModalScsChemo
        show={openFormScsChemo.show}
        data={openFormScsChemo.data}
        handleClose={() =>
          setOpenFormScsChemo( ( prev ) => ( {
            ...prev,
            show: false,
          } ) )
        }
        saved={() =>
          setOpenFormScsChemo( ( prev ) => ( {
            ...prev,
            saved: true,
          } ) )}
      />
      </Container>
  );
}
