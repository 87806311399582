import React, {ReactNode, useEffect, useState} from "react";
import {Button, Container, Nav, Table} from "react-bootstrap";
import {CartCheck, CheckCircle, Pen, PlusCircle, PrinterFill, Recycle, StarFill, Trash} from "react-bootstrap-icons";
import { apiService } from '../../functions/api-service';
import Swal from 'sweetalert2';
import { Alert } from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import {ChangeEvent, Department, OrderDetail, OrderTransaction} from "../../type";
import ModalDept from "../../components/modalDept";
import {format} from "date-fns";
import th from "date-fns/locale/th";
import FilterOrderTran from "../../components/filterOrderTran";
import DatePicker from "react-datepicker";
import {CSVDownload, CSVLink,} from "react-csv";
import moment from "moment";
import { Data } from "react-csv/lib/core";
import {useNavigate} from "react-router-dom";

export default function SynReport1() {
  const {isLoad, isLoaded, profile} = useContexts();
    const navigate = useNavigate();
    useEffect(() => {
        if((profile?.sub !== '0')){
            navigate("/")
        }
    }, []);

  const statusMachine = [
    {value: "1", lable: "Ready"},
    {value: "2", lable: "Running"},
    {value: "3", lable: "Finished"},
    {value: "4", lable: "Error"},
  ];
  const statusOrder = [
    {value: "1", lable: "รับแล้ว"},
    {value: "2", lable: "เช็คยา"},
    {value: "3", lable: "จ่ายยา"},
    {value: "99", lable: 'Error'},
  ];
  const [orderTranList, setOrderTranList] = useState<Array<any>>([]);

  const [searchOrderTran, setSearchOrderTran] = useState({
    orderStartDate: new Date(),
    orderEndDate: new Date(),
    statusMachine: "",
    statusOrder: "",

  });

  useEffect(() => {
    getOrderTranList().catch(console.error);
  }, [searchOrderTran.orderStartDate,
            searchOrderTran.orderEndDate,
            searchOrderTran.statusMachine,
            searchOrderTran.statusOrder]);

  const getOrderTranList = async () => {
    isLoad();
    setOrderTranList(
        await apiService.listSumByDate({
          sDate: format(new Date(searchOrderTran.orderStartDate), "yyyy-MM-dd"),
          eDate: format(new Date(searchOrderTran.orderEndDate), "yyyy-MM-dd"),
          statusMachine: searchOrderTran.statusMachine,
          statusOrder: searchOrderTran.statusOrder,
        })
        // await apiService.orderTranList({type:'report',createAt: format(new Date(searchOrderTran.orderStartDate), "yyyy-MM-dd"),createEnd : format(new Date(searchOrderTran.orderEndDate), "yyyy-MM-dd")})
    );
    isLoaded();
  };
  const handleChange = (e: ChangeEvent) => {
    setSearchOrderTran((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const csvData = [
    ["#","เดือน/ปี","ปริมาณงานผสมยาจากหุ่นยนต์", "", "", "", "ปริมาณงานผสมยาจากเภสัชกร", "", "", "ปริมาณงานผสมยาทั้งหมด","",""]
  ];

  csvData.push(["","","จำนวนตำรับยา (ID) ", "จำนวนผู้ป่วย (ราย)", "จำนวนตำรับยา (Treatment)", "จำนวนตำรับเกิน +- 5%", "จำนวนตำรับยาหุ่นขัดข้อง","จำนวนตำรับยา (ID) ", "จำนวนผู้ป่วย (ราย)", "จำนวนตำรับยา (Treatment)","จำนวนตำรับยา (ID) ","จำนวนผู้ป่วย (ราย)","จำนวนตำรับยา (Treatment)"])
  // compare data for display and export
  const orderList = [];
  for (let i = 0; i < orderTranList.length; i++) {
    let r = orderTranList[i];
    let teme:any =
        [ (i+1).toString(),
          (r.xM+'/'+r.xY),
          (r.mac_all),
          (r.mac_hn),
          (r.mac_treatment),
          (r.all_error5),
          (r.all_error),
          (r.phar_all),
          (r.phar_hn),
          (r.phar_treatment),
          (r.all_order),
          (r.all_hn),
          (r.all_treatment),

        ];

    csvData.push(teme)
  }

  return (
      <>
        <Container className={"mw-100"}>
          <h2>รายงานประจำเดือน</h2>
          {/*<div  className="mb-2">ค้นหาข้อมูลได้จาก รหัส และชื่อ</div>*/}
          <div className="mb-2 row bg-primary bg-opacity-25 p-1">
            <div className={"col-3"}>
              <label htmlFor="orderStartDate" className={"form-label"}>
                วันที่ {format(new Date(searchOrderTran.orderStartDate), "yyyy-MM-dd")}
              </label>
              <DatePicker
                  id={"orderStartDate"}
                  name={"orderStartDate"}
                  selected={searchOrderTran.orderStartDate}
                  onChange={(date: Date) =>
                      handleChange({target: {name: "orderStartDate", value: date}})
                  }
                  locale={th}
                  dateFormat={"dd-MM-yyyy"}
                  className={"form-control"}
              />

            </div>
            <div className={"col-3"}>
              <label htmlFor="orderEndDate" className={"form-label"}>
                วันที่สิ้นสุด {format(new Date(searchOrderTran.orderEndDate), "yyyy-MM-dd")}
              </label>
              <DatePicker
                  id={"orderEndDate"}
                  name={"orderEndDate"}
                  selected={searchOrderTran.orderEndDate}
                  onChange={(date: Date) =>
                      handleChange({target: {name: "orderEndDate", value: date}})
                  }
                  locale={th}
                  dateFormat={"dd-MM-yyyy"}
                  className={"form-control"}
              />

            </div>

            <div className={"col-2"}>
              <label htmlFor="statusMachine" className={"form-label"}>
                สถานะ Machine
              </label>
              <select
                  id="statusMachine"
                  name="statusMachine"
                  className="form-select"
                  onChange={(e) =>
                      handleChange({
                        target: {name: "statusMachine", value: e.target.value},
                      })
                  }
              >
                <option key={99}></option>
                {statusMachine.map((r, i) => (
                    <option value={r.value} key={i}>{r.lable}</option>
                ))}
              </select>
            </div>
            <div className={"col-2"}>
              <label htmlFor="statusOrder" className={"form-label"}>
                สถานะ Order
              </label>
              <select
                  id="statusOrder"
                  name="statusOrder"
                  className="form-select"
                  onChange={(e) =>
                      handleChange({
                        target: {name: "statusOrder", value: e.target.value},
                      })
                  }
              >
                <option key={99}></option>
                {statusOrder.map((r, i) => (
                    <option value={r.value} key={i}>{r.lable}</option>
                ))}
              </select>
            </div>
            <div className={"col-2 text-end"}>
              <CSVLink
                  data={csvData}
                  filename={"report-per-day_" + moment(new Date()).format("DD-MM-YYYY") + ".csv"}
                  className="btn btn-primary mt-3"
                  target="_blank"
              >
                Download
              </CSVLink>
            </div>
          </div>

          <Table striped bordered hover responsive={"xl"}>
            <thead className={'center'} style={{textAlign: "center",}}>
            <tr>
              <th rowSpan={2}>#</th>
              <th rowSpan={2}>เดือน/ปี</th>
              <th colSpan={5}>ปริมาณงานผสมยาจากหุ่นยนต์</th>
              <th colSpan={3}>ปริมาณงานผสมยาจากเภสัชกร</th>
              <th colSpan={3}>ปริมาณงานผสมยาทั้งหมด</th>
            </tr>
            <tr>
              <th>จำนวนตำรับยา (ID)</th>
              <th>จำนวนผู้ป่วย (ราย)</th>
              <th>จำนวนตำรับยา (Treatment)</th>
              <th>จำนวนตำรับเกิน +- 5%</th>
              <th>จำนวนตำรับยาหุ่นขัดข้อง</th>

              <th>จำนวนตำรับยา (ID)</th>
              <th>จำนวนผู้ป่วย (ราย)</th>
              <th>จำนวนตำรับยา (Treatment)</th>

              <th>จำนวนตำรับยา (ID)</th>
              <th>จำนวนผู้ป่วย (ราย)</th>
              <th>จำนวนตำรับยา (Treatment)</th>
            </tr>
            </thead>
            <tbody>
            {orderTranList && orderTranList.map((r, i) => (
                <tr>
                  <td> {i + 1}</td>
                  <td> {r.xM}/{r.xY}</td>
                  <td> {r.mac_all}</td>
                  <td> {r.mac_hn}</td>
                  <td> {r.mac_treatment}</td>
                  <td> {r.all_error5}</td>
                  <td> {r.all_error}</td>

                  <td> {r.phar_all}</td>
                  <td> {r.phar_hn}</td>
                  <td> {r.phar_treatment}</td>

                  <td> {r.all_order}</td>
                  <td> {r.all_hn}</td>
                  <td> {r.all_treatment}</td>
                </tr>
            ))}
            </tbody>
          </Table>
        </Container>

      </>
  );
}
