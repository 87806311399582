import React, { useEffect, useState } from "react";
import {Button, Modal, Form, InputGroup, Row, Col, Card} from "react-bootstrap";
import { Pen, PlusCircle } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import ModalScsChemo from "./modalScsChemo";
import { apiService } from '../functions/api-service';
import Select, {OnChangeValue} from "react-select";
import {ChangeEvent, DrugMac, Medicine, OptionSelect, ScsChemo} from '../type';
import { Alert, HandleChange, OptionSelects } from '../functions/utils';
import {useContexts} from "../context/AppContext";

type Props = {
  show: boolean;
  data: Medicine | null;
  handleClose: () => void;
  saved: () => void;
};

export default function ModalMedicine ( { show, data, handleClose, saved }: Props ) {
  const [IsNewMed, setIsNewMed] = useState( false );
  const [dataForm, setDataForm] = useState<Medicine | null>( null );
  const [openFormScsChemo, setOpenFormScsChemo] = useState<{ show: boolean; data: ScsChemo | null | undefined; saved: boolean; }>( { show: false, data: null, saved: false } );
  const [medUseList, setMedUseList] = useState<Array<OptionSelect>>([]);
  const [scsGemList, setScsGemList] = useState<Array<OptionSelect>>([]);
  const [machineList, setMachineList] = useState<Array<OptionSelect>>([]);
  const [selectedOption, setSelectedOption] = useState<DrugMac  | null>(null);
  const handleChange = (e: ChangeEvent) => HandleChange(setDataForm, e);
  const {profile } = useContexts();
  const handleSave = async ( closeForm = true ) => {
    console.log( 'dataForm', dataForm )
    if(dataForm){
      const res = await apiService.saveMed( dataForm );
    if ( res.success === true ) {      
      saved();
      setIsNewMed( false );
      if ( closeForm ) {
        handleClose();
        Alert(res.success, res.message);
      }
    }else{
      Alert(res.success, res.message);
    }
    return res?.success === true ? true : false
    }    
  }
  const [initScs, setInitScs] = useState<OptionSelect | null>(null);

  useEffect( () => {
    if ( show ) {
      if(data?.machine){
        data.tmtid = data?.machine?.tmtid;
      }
      setDataForm( data );
      setIsNewMed( data == null ? true : false );      
      getConst().then();
      if(data == null){
        handleChange({ target: { name: "productcat", value: '1' } });
      }

    } else {
      setDataForm( null );
      setSelectedOption(null);
    }
  }, [show] );

  useEffect( () => {
    if(dataForm?.scsList && dataForm.scsList.length > 0){
      const scs = dataForm.scsList.find(e => e.id == dataForm.scsId);
      setInitScs(scs ? {value: scs.id, label: scs?.scs?.name, data: scs} : null);
    }else{
      setInitScs(null);
    }
  }, [dataForm?.scsId]);

  useEffect( () => {
    if ( openFormScsChemo.saved ) {
      apiService.medList( { code: dataForm?.code, status: '1' } )
        .then( res => {
          if ( res.length > 0 ) {
            setDataForm( res[0] );
          }
        } )
      setOpenFormScsChemo( ( prev ) => ( {
        ...prev,
        saved: false,
      } ) )
    }
  }, [openFormScsChemo.saved] );

  const getConst = async () => {
    setMedUseList(OptionSelects(
      await apiService.medUseList({ status: "1" }),
      "${name}",
      "id"
    ));    
    setScsGemList(OptionSelects(
      await apiService.scsGemList({ status: "1" }),
      "${name}",
      "id"
    ));


    setMachineList(OptionSelects(
      await apiService.drugMacList({ status: "1" }),
        "${fullName}    ${volumeText}",
      "id"
    ));

    // setMachineList(await apiService.drugMacList({ status: "1" }).then());
  };
  // const handleSelectChange = (event: DrugMac | undefined) => {
  //   if(event) {
  //     setSelectedOption(event)
  //   }
  // };
  //
  // const customStyles = {
  //   option: (provided:any, state:any) => ({
  //     ...provided,
  //     display: 'flex',
  //     alignItems: 'center',
  //     backgroundColor: state.isFocused ? '#e6f7ff' : 'transparent', // Background color on hover
  //     '&:hover': {
  //       backgroundColor: '#e6f7ff', // Background color on hover
  //     },
  //   }),
  //   optionImage: {
  //     marginRight: '10px',
  //     width: '24px',
  //     height: '24px',
  //   },
  // };mac



  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{IsNewMed ? 'เพิ่มรายการยา' : 'แก้ไขยา: ' + dataForm?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className={"row row-cols-2"} onSubmit={( e ) => {
            e.preventDefault();
            handleSave().then();
          }}>
            <Form.Group className="col-4  mb-3" controlId="productcat">
              <Form.Label className={"me-2"}>ประเภท</Form.Label>
              <div className={"h5"}>
                <Form.Check
                  onChange={handleChange}
                  type="radio"
                  inline
                  label={"ยา"}
                  value={"1"}
                  name={"productcat"}
                  id={"radio-productcat-1"}
                  // defaultChecked={dataForm?.productcat === '1' ? true : false}
                  checked={dataForm?.productcat ? dataForm?.productcat === '1' ? true : false : true}
                />
                <Form.Check
                  onChange={handleChange}
                  type="radio"
                  inline
                  label={"น้ำเกลือ"}
                  value={"6"}
                  name={"productcat"}
                  id={"radio-productcat-2"}
                  // defaultChecked={dataForm?.productcat === '6' ? true : false}
                  checked={dataForm?.productcat === '6' ? true : false}
                />
              </div>
            </Form.Group>
            <Form.Group className="col-4 mb-3" controlId="code">
              <Form.Label>รหัส</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Code"
                onChange={handleChange}
                name={"code"}
                value={dataForm?.code}
                // disabled={dataForm?.code ? true : false}
              />
            </Form.Group>
            <Form.Group className="col-4 mb-3" controlId="tmtid">
              <Form.Label>TMT</Form.Label>
              <Form.Control
                  type="text"
                  placeholder="TMTID"
                  onChange={handleChange}
                  name={"tmtid"}
                  value={dataForm?.tmtid}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>ชื่อยา</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Medicine name"
                onChange={handleChange}
                name={"name"}
                value={dataForm?.name}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="machineMedName">
              <Form.Label>ชื่อที่ใช้ในหุ่นยนต์</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Medicine name in machine"
                onChange={handleChange}
                name={"machineMedName"}
                value={dataForm?.machineMedName}
              />
            </Form.Group>


              <Form.Group className="mb-3" controlId="strength">
                <Form.Label>Strength</Form.Label>
                <InputGroup>
                  <Form.Control
                      required
                      type="number"
                      placeholder="Strength"
                      onChange={handleChange}
                      name={"strength"}
                      value={dataForm?.strength}
                  />
                  <InputGroup.Text id="strength">
                    &nbsp;&nbsp; mg &nbsp;&nbsp;
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="solvent">
                <Form.Label>Volumn {profile?.site == '2'?'(ใช้สำหรับคำนวณค่า )':''}</Form.Label>
                <InputGroup>
                  <Form.Control
                      required
                      type="number"
                      placeholder="Solvent"
                      onChange={handleChange}
                      name={"solvent"}
                      value={dataForm?.solvent??0}
                  />
                  <InputGroup.Text id="solvent">
                    &nbsp;&nbsp; ml &nbsp;&nbsp;
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

            {dataForm?.productcat === '1' && (
                <>
              <Form.Group className="mb-3" controlId="gravity">
              <Form.Label>Gravity</Form.Label>
              <Form.Control
                type="text"
                placeholder="Gravity"
                onChange={handleChange}
                name={"gravity"}
                value={dataForm?.gravity}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="density">
              <Form.Label>Density</Form.Label>
              <Form.Control
                type="text"
                placeholder="Density"
                onChange={handleChange}
                name={"density"}
                value={dataForm?.density}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="scsGem">
              <Form.Label>น้ำเกลือที่ใช้ทำละลาย</Form.Label>             
                  <Select
                  value={dataForm?.scsGem?.id 
                    ? scsGemList.find((e) => e.value == dataForm.scsGem.id)
                    : null}
                  isSearchable={true}
                  name="scsGem"
                  id="scsGem"
                  options={scsGemList}
                  onChange={(e) => {
                    handleChange({ target: { name: "scsGem", value: e?.data ? e.data : null } });
                    if(!e?.data){
                      handleChange({ target: { name: "scsGemUnit", value: '' } })
                    }
                  }}
                  isClearable
                />
            </Form.Group>
            {dataForm?.scsGem && (
              <Form.Group className="mb-3" controlId="scsGemUnit">
              <Form.Label>ปริมาตร</Form.Label>
              <Form.Control
                type="text"
                placeholder="ปริมาตร น้ำเกลือที่ใช้ทำละลาย"
                onChange={handleChange}
                name={"scsGemUnit"}
                value={dataForm?.scsGemUnit}
              />
            </Form.Group>
            )}
            <Form.Group className="mb-3" controlId="scsId">
              <Form.Label>น้ำเกลือที่ใช้ผสม</Form.Label>
              <div className="mb-2 row">
                <div className={`${dataForm?.scsId ? 'col-8' : 'col-10'} pe-0`}>
                  <Select
                    value={initScs}
                    isSearchable={true}
                    name="scsId"
                    id="scsId"
                    options={dataForm?.scsList && dataForm?.scsList.length > 0 ? dataForm?.scsList.filter((e) => e.status).map(r => ({value: r.id, label: r?.scs?.name, data: r})) : []}
                    onChange={(e) => {
                      handleChange({ target: { name: "scsId", value: e?.value ? e.value : null } });
                    }}
                    isClearable
                  />
                </div>
                <div className={"col-2 d-grid px-0"}>
                  <Button
                    size={"sm"}
                    variant={'success'}
                    onClick={async () => {
                      if ( IsNewMed ) {
                        const confirmed = await Swal.fire( {
                          title: 'บันทึกข้อมูลยาใหม่? ก่อนเพิ่มน้ำเกลือ',
                          showCancelButton: true,
                          confirmButtonText: 'บันทึกข้อมูลยา',
                          cancelButtonText: 'ไม่บันทึกข้อมูลยา',
                        } );
                        if ( confirmed.isConfirmed ) {
                          if(!await handleSave( false )){
                            return;
                          }
                        }else{
                          return;
                        }
                      }

                      let tempData = { medCode: dataForm.code, med: dataForm };
                      setOpenFormScsChemo( {
                        show: true,
                        data: tempData,
                        saved: false
                      } );
                    }}
                  ><PlusCircle /></Button>
                </div>
                {dataForm?.scsId && ( <div className={"col-2 d-grid px-0"}>
                  <Button
                    size={"sm"}
                    variant={'primary'}
                    onClick={async () => {
                      setOpenFormScsChemo( {
                        show: true,
                        data: dataForm?.scsList ? dataForm.scsList.find( e => e.id === dataForm.scsId) : null,
                        saved: false
                      } );
                    }}
                  ><Pen /></Button>
                </div> )}
              </div>
            </Form.Group>
          
            <Form.Group className="mb-3" controlId="method">
              <Form.Label>วิธีใช้ยา</Form.Label>            
                  <Select
                  value={dataForm?.method?.id 
                    ? medUseList.find((e) => e.value == dataForm.method.id)
                    : null}
                  isSearchable={true}
                  name="method"
                  id="method"
                  options={medUseList.filter(
                    (e) => e.data.type === "วิธีใช้ยา"
                  )}
                  onChange={(e) => {
                    handleChange({ target: { name: "method", value: e?.data ? e.data : null } });
                  }}
                  isClearable
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="time">
              <Form.Label>เวลาที่ใช้</Form.Label>
              <Select
                  value={dataForm?.time?.id 
                    ? medUseList.find((e) => e.value == dataForm.time.id)
                    : null}
                  isSearchable={true}
                  name="time"
                  id="time"
                  options={medUseList.filter(
                    (e) => e.data.type === "เวลาที่ใช้"
                  )}
                  onChange={(e) => {
                    handleChange({ target: { name: "time", value: e?.data ? e.data : null } });
                  }}
                  isClearable
                />
            </Form.Group>

            <Form.Group className="col-12 mb-3" controlId="note">
              <Form.Label>คำเตือน</Form.Label>
              <Form.Control
                  name="note"
                  id="note"
                  value={dataForm.note}
                  onChange={handleChange}
                  as="textarea" rows={3} />
            </Form.Group>
              </>

            )}
            <hr className={"col-12"}/>

            <Form.Group className="col-12 mb-3" controlId="machine">
              <Form.Label>Machine  {dataForm?.machine?.id}</Form.Label>
              <Select
                  value={dataForm?.machine?.id
                      ? machineList.find((e) => e.value == dataForm.machine.id)
                      : null}
                  isSearchable={true}
                  name="machine"
                  id="machine"
                  options={machineList.filter(
                      (e) => e.data.status === "1"
                  )}
                  onChange={(e) => {
                    handleChange({ target: { name: "machine", value: e?.data ? e.data : null } });
                    handleChange({ target: { name: "tmtid", value: e?.data?.tmtid ? e.data?.tmtid : '' } });
                  }}
                  isClearable
              />

              {dataForm?.machine && (
                <div className={'mt-2 center w-100'} style={{display:' -webkit-inline-box'}}>

                  <Card style={{width:'20%'}}>
                    <Card.Img variant="top" src={`https://mth.ayapharma.tech/sync_backend/api/v0/download/images/${dataForm?.machine.imageName}.png`} alt={dataForm?.machine?.imageName} width="50%" />
                  </Card>
                  <Card style={{width:'80%'}} >
                    <Card.Body>
                    <Card.Title>{dataForm?.machine.fullName}</Card.Title>
                    <Card.Text>
                      <h6><strong>  Code: </strong> {dataForm?.machine.id}</h6>
                      <h6><strong>  Volume: </strong>  {dataForm?.machine.volumeText}</h6>

                    </Card.Text>
                  </Card.Body>
                </Card>


                 <Row>
                   <Col className={"col-4 px-1 mb-2"}>
                     <img   style={{
                     width: "10%",
                   }}/>
                   </Col>

                   <Col className={"col-2 px-1 mb-2"}>

                   </Col>
                 </Row>
                </div>
            )}

            </Form.Group>

            <hr className={"col-12"}/>
            <div className="w-100 text-end">
              <Button variant="secondary" size="lg" onClick={handleClose}>
                ปิด
              </Button>{" "}
              <Button variant="primary" type="submit" size="lg">
                บันทึก
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ModalScsChemo
        show={openFormScsChemo.show}
        data={openFormScsChemo.data ? openFormScsChemo.data : null}
        handleClose={() =>
          setOpenFormScsChemo( ( prev ) => ( {
            ...prev,
            show: false,
          } ) )
        }
        saved={() =>
          setOpenFormScsChemo( ( prev ) => ( {
            ...prev,
            saved: true,
          } ) )}
      />
    </>
  );
}
