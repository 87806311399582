import { useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import { apiService } from '../functions/api-service';
import Select from "react-select";
import { ChangeEvent, OptionSelect, ScsChemo } from '../type';
import { Alert, HandleChange, OptionSelects } from '../functions/utils';

type Props = {
  show: boolean;
  data: ScsChemo | null;
  handleClose: () => void;
  saved: () => void;
};

export default function ModalScsChemo({ show, data, handleClose, saved }: Props) {
  const [dataForm, setDataForm] = useState<ScsChemo | null>(null);
  const [scsList, setScsList] = useState<Array<OptionSelect>>([]);
  const [medList, setMedList] = useState<Array<OptionSelect>>([]);
  const handleChange = (e: ChangeEvent) => HandleChange(setDataForm, e);
  const handleSave = async () => {
    console.log( 'dataForm--<>', dataForm);
    if(dataForm){
      dataForm.stableType = dataForm.stableType ? dataForm.stableType : 'ชัวโมง'
      dataForm.scsCode = (dataForm.id ? dataForm.scsCode :'scs_'+dataForm?.med?.code+'_'+dataForm?.scs?.code)
      const res = await apiService.saveScsChemo( dataForm );
      if ( res.success === true ) { 
        saved();
        handleClose();
      }else{
        Alert(res.success, res.message);
      }
    }   
  }
  let IsNew = true;
  useEffect(() => {
    if (show) {
      getMedList().then();
      getSCSList().then();
      if (!data?.scsCode) {
        // getMaxScsId().then();
      }
      setDataForm(data);
      IsNew = !dataForm?.scsCode;
    } else {
      setDataForm(null);
    }
  }, [show]);

  const getSCSList = async () => {
    setScsList(OptionSelects(
      await apiService.medList({"productcat": '6', status: '1'}),
      "${name}",
      "code"
    ));
  };
  const getMedList = async () => {
    setMedList(OptionSelects(
      await apiService.medList({"productcat": '1', status: '1'}),
      "${name}",
      "code"
    ));
  };

  const getMaxScsId = async () => {
    const res = await apiService.maxIdScsChemo();
    if (res.success) {
      const maxId = `SCS_${res.data.maxId.toString().padStart(3, "0")}`
      setDataForm( ( prev: any ) => ( {
        ...prev,
        scsCode: maxId,
      } ) )
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className={"pb-0"} closeButton>
        <Modal.Title>
          {IsNew
            ? "แก้ไขข้อมูลน้ำเกลือใช้ผสม "
            : "เพิ่มข้อมูลน้ำเกลือใช้ผสม "}
            <div className={"fs-5 fw-normal"}>&nbsp;&nbsp;
              {dataForm?.med?.name}
              {/*{dataForm?.scsCode}*/}
                  </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className={"row row-cols-1"} onSubmit={(e) => {
        e.preventDefault();
        handleSave().then();
      }}>
          <Form.Group className="mb-3" controlId="scsCode" >
            <Form.Label>รหัส</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Code"
              onChange={handleChange}
              name={"scsCode"}
              value={dataForm?.scsCode}
              // value={dataForm?.id ? dataForm?.scsCode :'scs_'+dataForm?.med?.code+'_'+dataForm?.scs?.code}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="scs">
            <Form.Label>รายการยา</Form.Label>
            <Select
                value={dataForm?.med?.code
                    ? medList.find((e) => e.value == dataForm?.med?.code)
                    : null}
                isSearchable={true}
                name="med"
                id="med"
                options={medList}
                isDisabled={!!dataForm?.id}
                onChange={(e) => {

                  handleChange({ target: { name: "med", value: e?.data } });
                }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="scs">
            <Form.Label>น้ำเกลือที่ใช้ผสม</Form.Label>            
            <Select
                  value={dataForm?.scs?.code 
                    ? scsList.find((e) => e.value == dataForm?.scs?.code)
                    : null}
                  isSearchable={true}
                  name="scs"
                  id="scs"
                  options={scsList}
                  onChange={(e) => {
                    handleChange({ target: { name: "scs", value: e?.data } });
                  }}
                />
          </Form.Group>

          <Form.Group className="mb-3" controlId="stableTime">
            <Form.Label>เวลาคงตัว</Form.Label>
            <div className="mb-2 row">
              <div className={"col-8 pe-0"}>
                <Form.Control
                  type="text"
                  placeholder="เวลาคงตัว"
                  onChange={handleChange}
                  name={"stableTime"}
                  value={dataForm?.stableTime}
                />
              </div>
              <div className={"col-4 pe-0"}>
                <Form.Group className="mb-3" controlId="stableType">
                  <Form.Check
                    onChange={handleChange}
                    type="radio"
                    inline
                    label={"ชั่วโมง"}
                    value={"ชั่วโมง"}
                    name={"stableType"}
                    id={"radio-stableType-1"}
                    checked={dataForm?.stableType ? dataForm.stableType === "ชั่วโมง" ? true : false : true}
                  />
                  <Form.Check
                    onChange={handleChange}
                    type="radio"
                    inline
                    label={"วัน"}
                    value={"วัน"}
                    name={"stableType"}
                    id={"radio-stableType-2"}
                    checked={dataForm?.stableType === "วัน" ? true : false}
                  />
                </Form.Group>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="temperature">
            <Form.Label>อุณหภูมิ</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="อุณหภูมิ"
                onChange={handleChange}
                name={"temperature"}
                aria-describedby="g-temperature"
                value={dataForm?.temperature}
              />
              <InputGroup.Text id="g-temperature">
                &nbsp;&nbsp; °C &nbsp;&nbsp;
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <div className="text-end">
            <Button variant="secondary" onClick={handleClose}>
              ปิด
            </Button>{" "}
            <Button variant="primary" type="submit">
              บันทึก
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
