import React, { useEffect, useState } from "react";
import {Button, Modal, Form, InputGroup, Card, Row, Col} from "react-bootstrap";
import { apiService } from '../functions/api-service';
import {ChangeEvent, OptionSelect, ScsGem} from '../type';
import { Alert, HandleChange, OptionSelects } from '../functions/utils';
import Select from "react-select";

type Props = {
  show: boolean;
  data: ScsGem | null;
  handleClose: () => void;
  saved: () => void;
};

export default function ModalScsGem ( { show, data, handleClose, saved }: Props ) {
  let IsNew = true;
  const [dataForm, setDataForm] = useState<ScsGem | null>( null );
  const [machineList, setMachineList] = useState<Array<OptionSelect>>([]);


  const handleChange = (e: ChangeEvent) => HandleChange(setDataForm, e);

  const handleSave = async () => {
    console.log( 'ModalScsGem dataForm', dataForm )
    if(dataForm){
      const res = await apiService.saveScsGem( dataForm );
      if ( res.success === true ) {
        saved();
        handleClose();
      }
      Alert(res.success, res.message);
    }    
  }

  useEffect(() => {

    if ( show ) {
      setDataForm( data );
      console.log( 'data', data );
      IsNew = !data?.name;
      getConst().then();
    } else {
      setDataForm( null );
    }
  }, [show]);


  const getConst = async () => {
    setMachineList(OptionSelects(
        await apiService.drugMacList({ status: "1" }),
        "${fullName}    ${volumeText}",
        "id"
    ));

    // setMachineList(await apiService.drugMacList({ status: "1" }).then());
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{IsNew ? `เพิ่มรายการ` : `แก้ไข: ${dataForm?.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className={"row row-cols"} onSubmit={( e ) => {
            e.preventDefault();
            handleSave().then();
          }}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>ชื่อยา</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="วิธีใช้ยา"
                onChange={handleChange}
                name={"name"}
                value={dataForm?.name}
              />
            </Form.Group>

            <Form.Group className="col-12 mb-3" controlId="machine">
              <Form.Label>Machine  {dataForm?.machine?.id}</Form.Label>
              <Select
                  value={dataForm?.machine?.id
                      ? machineList.find((e) => e.value == dataForm.machine.id)
                      : null}
                  isSearchable={true}
                  name="machine"
                  id="machine"
                  options={machineList.filter(
                      (e) => e.data.status === "1"
                  )}
                  onChange={(e) => {
                    handleChange({ target: { name: "machine", value: e?.data ? e.data : null } });
                  }}
                  isClearable
              />

              {dataForm?.machine && (
                  <div className={'mt-2 center w-100'} style={{display:' -webkit-inline-box'}}>

                    <Card style={{width:'20%'}}>
                      <Card.Img variant="top" src={`https://mth.ayapharma.tech/sync_backend/api/v0/download/images/${dataForm?.machine.imageName}.png`} alt={dataForm?.machine?.imageName} width="50%" />
                    </Card>
                    <Card style={{width:'80%'}} >
                      <Card.Body>
                        <Card.Title>{dataForm?.machine.fullName}</Card.Title>
                        <Card.Text>
                          <h6><strong>  Code: </strong> {dataForm?.machine.id}</h6>
                          <h6><strong>  Volume: </strong>  {dataForm?.machine.volumeText}</h6>

                        </Card.Text>
                      </Card.Body>
                    </Card>


                    <Row>
                      <Col className={"col-4 px-1 mb-2"}>
                        <img   style={{
                          width: "10%",
                        }}/>
                      </Col>

                      <Col className={"col-2 px-1 mb-2"}>

                      </Col>
                    </Row>
                  </div>
              )}

            </Form.Group>


            <div className="w-100 text-end">
              <Button variant="secondary" size="lg" onClick={handleClose}>
                ปิด
              </Button>{" "}
              <Button variant="primary" type="submit" size="lg">
                บันทึก
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>    
    </>
  );
}
