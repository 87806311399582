import axios from "axios";
import { Alert } from "./utils";
import Swal from "sweetalert2";
import {
  MedUse,
  Medicine,
  OrderTransaction,
  Person,
  Prefix,
  ScsChemo,
  ScsGem,
  Department,
  User,
  DrugMac,
  changPass, OrderDetail, medicineView
} from "../type";
import {promises} from "dns";

//set url apiServer
const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === "production" ?
      "https://mth.ayapharma.tech//backend/" :
      "http://localhost:3001/",
});
const axiosAYA = axios.create({
  baseURL: process.env.NODE_ENV === "production" ?
      "https://mth.ayapharma.tech/sync_backend/" :
      "https://mth.ayapharma.tech/sync_backend/",
  auth : {
    username: 'Acha',
    password: 'Acha1234'
  },
});

interface Response<T> {
  affected: 0;
  success: boolean;
  message: string;
  data: T;
}

class ApiService {
  constructor() {
    const accessToken = localStorage.getItem("accessToken");
    axiosInstance.interceptors.request.use(function (config) {
      config.headers.Authorization = "Bearer " + accessToken;
      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        console.error("interceptors err", err);
        if (err.constructor.name === "Cancel") {
          throw new Error("Request Cancel");
        }
        return new Promise((resolve, reject) => {
          console.log("err.response api", err.response);
          if (err.response) {
            // Alert(false, err?.response?.data?.message? err?.response?.data?.message : err?.response?.data);
            reject(err.response);
          } else {
            Swal.fire({
              icon: "error",
              title: "ไม่สามารถติดต่อ Server กรุณาลองใหม่อีกครั้ง",
              showConfirmButton: true,
              confirmButtonText: "Refresh page",
              showCancelButton: true,
              cancelButtonText: "ปิด",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            })
          }
        });
      }
    );
  }

  async signIn(username: string, password: string) {
    try {
      const res = await axiosInstance.post("/user/login", {
        username,
        password,
      });
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }

  }
  async userList(filter = {}): Promise<Array<User>> {
    try {
        const res = await axiosInstance.get("/user", {params: filter});
        return res.data;
      } catch (e) {
        return [];
      }
  }
  async saveUser(data: User): Promise<Response<User>> {
    try {
      const res = await axiosInstance.post("/user", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }
  async updatePass(data: changPass): Promise<Response<changPass>> {
    try {
      const res = await axiosInstance.post("/user/update-pass", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async updateUser(data: User): Promise<Response<User>> {
    try {
      const res = await axiosInstance.post("/user", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removeUser(id: number): Promise<Response<null>> {
    try {
      const res = await axiosInstance.delete("/user/" + id);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }



  async orderTranList(filter = {}): Promise<Array<OrderTransaction>> {
    try {
      const res = await axiosInstance.get("/order-transaction", {
        params: filter,
      });
      return res.data;
    } catch (e) {
      return [];
    }
  }

  async orderTranDateList(filter = {}): Promise<Array<OrderTransaction>> {
    try {
      const res = await axiosInstance.get("order-transaction/list-date", {
        params: filter,
      });
      return res.data;
    } catch (e) {
      return [];
    }
  }

  async listSumTreatment(filter = {}): Promise<Array<any>> {
    try {
      const res = await axiosInstance.get("order-transaction/list-sum-treatment", {
        params: filter,
      });
      return res.data;
    } catch (e) {
      return [];
    }
  }

  async listSumMedication(filter = {}): Promise<Array<any>> {
    try {
      const res = await axiosInstance.get("order-transaction/list-sum-medication", {
        params: filter,
      });
      return res.data;
    } catch (e) {
      return [];
    }
  }
  async listSumByDate(filter = {}): Promise<Array<any>> {
    try {
      const res = await axiosInstance.get("order-transaction/list-sum-date", {
        params: filter,
      });
      return res.data;
    } catch (e) {
      return [];
    }
  }

  async saveOrderTran(dataForm: OrderTransaction, amount: { perDay: number; day: number; startDate: Date }): Promise<Response<OrderTransaction>> {
    try {
      const res = await axiosInstance.post("/order-transaction", {
        dataForm,
        amount,
      });
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async remedOrderTran(dataForm: any): Promise<Response<OrderTransaction>> {
    try {
      const res = await axiosInstance.post("/order-transaction/remed-order", {
        hn:dataForm.person.hn,
        orderDate:dataForm.orderDate,
      });
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }
  async saveOrderDetail(site: string  | undefined ,orderID: string | undefined,mdeCode: string | undefined ): Promise<Response<OrderDetail>> {

      let detail ={},history ={},syringe ={},med ={};
      let sub = site == '2' ? 'ubon/':'';
      const resTask = await axiosAYA.get(sub+"api/v0/jobs/qrid/"+ orderID );

      console.log(resTask)
      if (resTask && resTask.data.data && resTask.data.data.id) {
        const resDetail = await axiosAYA.get(sub+"api/v0/logweight/subquery_logweight/" + resTask.data.data.id);

        // resDetail.data.subquery.map(r =>)
        // detail =  await (resDetail.data.data.subquery.sort((a: { itemid: number; }, b: { itemid: number; }) => b.itemid - a.itemid));
        detail = (resDetail.data.data.subquery);
        history = resDetail?.data?.data?.history
        syringe = resDetail?.data?.data?.syringe
        med = resDetail?.data?.data?.med
        try {
          const res = await axiosInstance.post("/order-transaction/order-detail", {
            detail,
            history,
            syringe,
            med,
            mdeCode,
            orderID
          });
          return res.data;
        } catch (e) {
          return this.catchTemplate(e);
        }
      } else {
        return this.catchTemplate({message: 'Response fail read api not found.'});
      }
  }

  async updateOrderStatus(orderID: string | undefined, status: string): Promise<Response<OrderTransaction>> {
    try {
      const res = await axiosInstance.get("/order-transaction/update-status/" + orderID+"/"+status);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }
  async updateOrderPrint(orderID: string | undefined, print: number): Promise<Response<OrderTransaction>> {
    try {
      const res = await axiosInstance.get("/order-transaction/update-print/" + orderID+"/"+print);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removeOrderTran(orderID: string): Promise<Response<OrderTransaction>> {
    try {
      const res = await axiosInstance.delete("/order-transaction/" + orderID);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async prefixList(filter = {}): Promise<Array<Prefix>> {
    try {
      const res = await axiosInstance.get("/person/prefix", { params: filter });
      return res.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async savePrefix(data: Prefix): Promise<Response<Prefix>> {
    try {
      const res = await axiosInstance.post("/person/prefix", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removePrefix(id: number): Promise<Response<null>> {
    try {      
      const res = await axiosInstance.delete("/person/prefix/" + id);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async personList(search: string, page = 1, perPage = 500): Promise<Array<Person>> {
    try {
      const res = await axiosInstance.get('/person', {params: {search, page, perPage}});
      return res.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async savePerson(data: Person): Promise<Response<Person>> {
    try {
      const res = await axiosInstance.post("/person", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removePerson(hn: string): Promise<Response<null>> {
    try {      
      const res = await axiosInstance.delete("/person/" + hn);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async medUseList(filter = {}): Promise<Array<MedUse>> {
    try {
      const res = await axiosInstance.get("/medicine/med-use", { params: filter });
      return res.data;
    } catch (e) {
      return [];
    }
  }

  async saveMedUse(data: MedUse): Promise<Response<MedUse>> {
    try {
      const res = await axiosInstance.post("/medicine/med-use", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removeMedUse(id: number): Promise<Response<null>> {
    try {      
      const res = await axiosInstance.delete("/medicine/med-use/" + id);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async medList(filter = {}): Promise<Array<Medicine>> {
    try {
      const res = await axiosInstance.get("/medicine", { params: filter });
      return res.data;
    } catch (e) {
      return [];
    }
  }

  async saveMed(data: Medicine): Promise<Response<Medicine>> {
    try {
      const res = await axiosInstance.post("/medicine", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removeMed(id: number): Promise<Response<null>> {
    try {      
      const res = await axiosInstance.delete("/medicine/" + id);
      return res.data;
    } catch (e) {
      console.log(e);
      return this.catchTemplate(e);
    }
  }

  async scsChemoList(filter = {}): Promise<Array<ScsChemo>> {
    try {
      const res = await axiosInstance.get("/medicine/scs-chemo", { params: filter });
      return res.data;
    } catch (e) {
      return [];
    }
  }

  async saveScsChemo(data: ScsChemo): Promise<Response<ScsChemo>> {
    try {
      const res = await axiosInstance.post("/medicine/scs-chemo", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removeScsChemo(id: number): Promise<Response<null>> {
    try {
      const res = await axiosInstance.delete("/medicine/scs-chemo/" + id);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async scsGemList(filter = {}): Promise<Array<ScsGem>> {
    try {
      const res = await axiosInstance.get("/medicine/scs-gem", { params: filter });
      return res.data;
    } catch (e) {
      return [];
    }
  }

  async saveScsGem(data: ScsGem): Promise<Response<ScsGem>> {
    try {
      const res = await axiosInstance.post("/medicine/scs-gem", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removeScsGem(id: number): Promise<Response<null>> {
    try {
      const res = await axiosInstance.delete("/medicine/scs-gem/" + id);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }


  async drugMacList(filter = {}): Promise<Array<DrugMac>> {
    try {
      const res = await axiosInstance.get("/medicine/drug-mac", { params: filter });
      return res.data;
    } catch (e) {
      return [];
    }
  }

  async saveDrugMac(data: DrugMac): Promise<Response<DrugMac>> {
    try {
      const res = await axiosInstance.post("/medicine/drug-mac", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removeDrugMac(id: string,status:string): Promise<Response<null>> {
    try {
      const res = await axiosInstance.delete("/medicine/drug-mac/" + id+"/"+status);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }


  async medicineViewList(filter = {}): Promise<Array<medicineView>> {
    try {
      const res = await axiosInstance.get("/medicine/med-view", { params: filter });
      return res.data;
    } catch (e) {
      return [];
    }
  }

  async saveMdicineView(data: medicineView): Promise<Response<medicineView>> {
    try {
      const res = await axiosInstance.post("/user/med-view", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removeMedicineView(id: number,status:string): Promise<Response<null>> {
    try {
      const res = await axiosInstance.delete("/medicine/med-view/" + id+"/"+status);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async maxIdScsChemo(): Promise<Response<{maxId: number}>> {
    try {
      const res = await axiosInstance.get("/medicine/scs-chemo");
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  private catchTemplate(e: any) {
    console.log(e);
    if (e?.response?.data) {
      return Object.assign({ success: false }, e.response.data);
    } else {
      return {
        success: false,
        message: e.message ?? "Response fail server error",
      };
    }
  }

  // departmeont
  async deptList(filter = {}): Promise<Array<Department>> {
    try {
      const res = await axiosInstance.get("/person/dept", { params: filter });
      return res.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async saveDept(data: Department): Promise<Response<Department>> {
    try {
      const res = await axiosInstance.post("/person/dept", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removeDept(id: number): Promise<Response<null>> {
    try {
      const res = await axiosInstance.delete("/person/dept/" + id);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  // departmeont
  async treatmentList(filter = {}): Promise<Array<Department>> {
    try {
      const res = await axiosInstance.get("/person/treatment", { params: filter });
      return res.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async saveTreatment(data: Department): Promise<Response<Department>> {
    try {
      const res = await axiosInstance.post("/person/treatment", data);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }

  async removeTreatment(id: number): Promise<Response<null>> {
    try {
      const res = await axiosInstance.delete("/person/treatment/" + id);
      return res.data;
    } catch (e) {
      return this.catchTemplate(e);
    }
  }



  async updateDrugMac(site: string  | undefined):  Promise<null>{

    const medMacs = await axiosAYA.get("ubon/api/v0/item_data");

    if (medMacs && medMacs.data && medMacs.data?.data.length > 0) {
      try {
        medMacs.data?.data.map(
            async (r: any) => {
              console.log(r)
              r.site =site;
              const res = await axiosInstance.post("/medicine/drug-mac", r);
        })

        return null;
      } catch (e) {
        return this.catchTemplate(e);
      }
    } else {
      return this.catchTemplate({message: 'Response fail read api not found.'});
    }
  }

}

export const apiService = new ApiService();
