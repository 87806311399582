import {useEffect, useState} from "react";
import {Button, Modal, Form, InputGroup, Table} from "react-bootstrap";
import {Pen, PlusCircle, Trash} from "react-bootstrap-icons";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import Select from "react-select";
import ModalPerson from "./modalPerson";
import DatePicker from "react-datepicker";
import th from "date-fns/locale/th";
import Swal from "sweetalert2";
import {format} from "date-fns";
import {Alert, HandleChange, OptionSelects} from '../functions/utils';
import {ChangeEvent, OptionSelect, OrderTransaction, Person, ScsChemo} from '../type';
import {apiService} from '../functions/api-service';
import {useContexts} from "../context/AppContext";
import BtnPrintQrChemo from "./printQrChemo";

type Props = {
    show: boolean;
    data: OrderTransaction | null;
    handleClose: () => void;
    saved: () => void;
};

export default function ModalRemedOrderTransaction({show, data, handleClose, saved,}: Props) {
    const {isLoad, isLoaded} = useContexts();
    const [IsNewTran, setIsNewTran] = useState(false);
    const [dataForm, setDataForm] = useState<OrderTransaction | null>(null);
    const [openFormPerson, setOpenFormPerson] = useState<{
        show: boolean;
        data: Person | null;
        saved: boolean;
        hn: string;
    }>({show: false, data: null, saved: false, hn: ""});
    const handleChange = (e: ChangeEvent) => HandleChange(setDataForm, e);

    const handleSave = (closeForm = true) => {
        console.log("handleSave dataForm", dataForm);
        if (dataForm?.orderDate) {
            Swal.fire({
                title: `Re med รายการยา`,
                html: `<h5><p>Re med รายการยาของ ${dataForm?.person?.fullName} วันที่ ${dataForm?.orderDate}</p></h5>`,
                showCancelButton: true,
                confirmButtonText: 'บันทึกข้อมูล',
                cancelButtonText: 'ยกเลิก',
            }).then(async result => {
                if (result.isConfirmed) {

                    console.log('xxx-->', dataForm)
                    const res = await apiService.remedOrderTran(dataForm);
                    if (res.success === true) {
                        saved();
                        setIsNewTran(false);
                        if (closeForm) {
                            handleClose();
                            Alert(res.success, res.message);
                        }
                    } else {
                        Alert(res.success, res.message);
                    }
                }
            });
        }
    };


    const [orderReTranList, setOrderRmTranList] = useState<Array<OrderTransaction>>([]);
    const getOrderRmTranList = async (id: number | undefined, createDate: any) => {
        if(createDate) {
            setOrderRmTranList(
                await apiService.orderTranList({hn: id, createAt: format(new Date(createDate), "yyyy-MM-dd")})
            );
        }else {
            setOrderRmTranList([])
        }
    };

    useEffect(() => {
        if (show) {
            console.log('dataForm', dataForm);
            console.log('data', data);
            setDataForm(data);
            setIsNewTran(data?.orderID == null ? true : false);
        } else {
            setDataForm(null);
        }
    }, [show]);

    useEffect(() => {
        if (openFormPerson.saved) {
            //init person add new
            apiService.personList(openFormPerson.hn).then((res) => {
                if (res.length > 0) {
                    setDataForm((prev) => ({
                        ...prev,
                        person: res[0],
                    }));
                }
            });

            setOpenFormPerson((prev) => ({
                ...prev,
                saved: false,
                hn: "",
            }));
        }
    }, [openFormPerson.saved]);

    const loadOptions = debounce(
        async (inputText: string, callback: (person: OptionSelect[]) => void) => {
            let options: OptionSelect[] = [];
            const res = await apiService.personList(inputText);
            const list = res.filter(e => e.status);
            if (list.length > 0) {
                try {
                    options = OptionSelects(
                        list,
                        "HN:${hn} - ${prefix} ${fullName}",
                        "hn"
                    );
                } catch (error: any) {
                    Alert(false, error.message);
                    return options;
                }
            }
            callback(options);
            return options;
        },
        350
    );

    const [orderDateList, setOrderDateList] = useState<Array<any>>([]);
    const getOrderDateList = async (id: number | undefined) => {
        // isLoad();
        setOrderDateList(
            await apiService.orderTranDateList({id: id})
        );
        // isLoaded();
    };
    const trDetail = (r: OrderTransaction, i: number) => (<tr key={i}>
        <td>
            {r.chemoPull && parseFloat(r.chemoPull) > 0 && r.chemoPull}
            {r.chemoPullGem && parseFloat(r.chemoPullGem) > 0 && r.chemoPullGem}
        </td>
        <td>{r.med?.name}
            <div>
                <small>Day : {r.noDay}</small>
                <small> ขวดที่ {r.noInDay}</small></div>
        </td>
        <td>
            {r.dosageOld && `${r.dosageOld} จำนวน ${r.amountOld}`}
        </td>
        <td>{r.dosage1 && `${r.dosage1} จำนวน ${r.amount1} ขวด`}</td>
        <td>{r.dosage2 && `${r.dosage2} จำนวน ${r.amount2} ขวด`}</td>
        <td>
            {r.med?.scsGem?.name && r.med.scsGem.name + r.scsGemUnitOld}
        </td>
        <td>
            {r?.scsId && r?.med && r?.med?.scsList.length > 0 &&
                r.med.scsList.find(e => e.id === r.scsId)?.scs?.name}
        </td>
        <td>{(r.method?.name ?? '') + " " + (r.time?.name ?? '')}</td>
    </tr>)

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
                size={"xl"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {"Re med Orders"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSave();
                        }}
                    >
                        <div className={"row row-cols-1"}>
                            <Form.Group className="mb-3" controlId="person">
                                <Form.Label>ค้นหาผู้ป่วย</Form.Label>
                                <div className="row">
                                    <div className={`${dataForm?.person ? "col-11" : "col-12"} pe-0`}>
                                        <AsyncSelect
                                            isDisabled={!IsNewTran}
                                            required
                                            id={"person"}
                                            cacheOptions
                                            // @ts-ignore
                                            loadOptions={loadOptions}
                                            defaultOptions
                                            placeholder={"HN หรือ ชื่อ-สกุล"}
                                            onChange={(e) => {
                                                handleChange({target: {name: "person", value: e?.data},})
                                                getOrderDateList(e?.data?.id).then();
                                                getOrderRmTranList(dataForm?.person?.id, '').then();
                                            }
                                            }
                                            noOptionsMessage={() => "ไม่พบข้อมูล"}
                                            value={
                                                dataForm?.person
                                                    ? {
                                                        label: `HN:${dataForm.person.hn} - ${dataForm?.person?.fullName}`,
                                                        value: dataForm.person.hn,
                                                        data: dataForm.person,
                                                    }
                                                    : null
                                            }
                                        />
                                    </div>
                                    {IsNewTran && (
                                        <>
                                            {dataForm?.person && (
                                                <div className={"col-1 d-grid px-0"}>
                                                    <Button
                                                        size={"sm"}
                                                        variant={"primary"}
                                                        onClick={() => {
                                                            setOpenFormPerson({
                                                                show: true,
                                                                data: dataForm?.person ? dataForm.person : null,
                                                                saved: false,
                                                                hn: dataForm?.person?.hn ?? '',
                                                            });
                                                        }}
                                                    >
                                                        <Pen/>
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Form.Group>


                            {(dataForm?.person && orderDateList.length > 0) && <div className={"md-3"}>
                                <label htmlFor="orderDate" className={"form-label"}>
                                    รายการวันที่สั่งยา
                                </label>
                                <select
                                    id="orderDate"
                                    name="orderDate"
                                    className="form-select"
                                    onChange={(e) => {
                                        handleChange({target: {name: "orderDate", value: e.target.value},});
                                        getOrderRmTranList(dataForm?.person?.id, e.target.value).then();
                                        }
                                    }
                                >
                                    <option key={99}></option>
                                    {orderDateList.map((r, i) => (
                                        <option value={r.code} key={i}>{r.value}</option>
                                    ))}
                                </select>
                            </div>}

                        </div>

                        {(dataForm?.person && orderDateList.length > 0 && orderReTranList)  && <div>
                            <hr/>
                            <Table striped bordered hover responsive={"xl"}>
                                <thead>
                                <tr>
                                    <th>ดูด</th>
                                    <th>รายการยา</th>
                                    <th>ขวดที่เหลือ</th>
                                    <th>ขวดที่ 1</th>
                                    <th>ขวดที่ 2</th>
                                    <th>น้ำเกลือทำละลาย</th>
                                    <th>น้ำเกลือผสม</th>
                                    <th>วิธีใช้ยา</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orderReTranList && orderReTranList.map((r, i) => {
                                    return trDetail(r, i);
                                })}
                                </tbody>
                            </Table>
                        </div>}

                        <hr/>

                        <div className="w-100 text-end">
                            <Button variant="secondary" size="lg" onClick={handleClose}>
                                ปิด
                            </Button>{" "}
                            <Button variant="primary" type="submit" size="lg">
                                Re med
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <ModalPerson
                show={openFormPerson.show}
                data={openFormPerson.data}
                handleClose={() =>
                    setOpenFormPerson((prev) => ({
                        ...prev,
                        show: false,
                    }))
                }
                saved={() =>
                    setOpenFormPerson((prev) => ({
                        ...prev,
                        saved: true,
                    }))
                }
                setHn={(hn: string) =>
                    setOpenFormPerson((prev) => ({
                        ...prev,
                        hn: hn,
                    }))
                }
            />
        </>
    );
}
