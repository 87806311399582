import Swal from "sweetalert2";
import { ChangeEvent, Children, OptionSelect } from '../type';
import { useContexts } from "../context/AppContext";
import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

const Alert = (status: boolean, message: string) => {
  Swal.fire({
    icon: status ? "success" : "error",
    title: message,
    showConfirmButton: status === true ? false : true,
    timer: status === true ? 1500 : 60000,
  });
};

function flatKeys(inputObject: any): { [key: string]: any } {
  const response: { [key: string]: any } = {};
  function iterative(currentObject: any, parentKeys: string[] = []) {
    const llaves = Object.keys(currentObject);
    for (let i = 0; i < llaves.length; i++) {
      const llave: string = llaves[i];
      const valor = currentObject[llave];
      const llavesNext = parentKeys.concat(llave);
      if (typeof valor == "object" && valor) {
        iterative(valor, llavesNext);
      } else {
        response[llavesNext.join(".")] = valor;
      }
    }
  }
  iterative(inputObject);
  return response;
}

function interpolate(template: string, values: any, defaultValue = "") {
  const flatedValues = flatKeys(values);
  const interpolated = template.replace(/\${(.*?)}/g, function (x, g) {
    const value = flatedValues[g];
    if ([undefined, null].indexOf(value) >= 0) {
      return defaultValue;
    }
    return value;
  });
  return interpolated;
}

const OptionSelects = (data: Array<any>, labelTemplateL: string, valueKey: string): OptionSelect[] => {
  const options: OptionSelect[] = [];
  if (data && data.length > 0) {
    data.forEach((row) => {          
      options.push({
        label: interpolate(labelTemplateL, row),
        value: row[valueKey],
        data: row,
      });    
    }); 
  }

  return options;
}
// <div style="overflow:hidden;"><div class="media"><div class="media-left"><img src="https://his-storage.udch.work/images/nopic.png" class="media-object img-rounded" style="width:64px;"></div><div class="media-body"><div class="row"><div class="col-sm-12"><div style="margin-bottom: 5px;font-size: 17px;"><strong>Set IV Infusion pump</strong> </div><div style="margin-bottom: 5px;font-size: 13px;">วิธีใช้ : 1     </div><div style="margin-bottom: 5px;font-size: 14px;">ราคาเบิกไม่ได้ : <code>81.82</code> ราคาเบิกได้ : <code>81.82</code></div></div></div></div></div></div>
const HandleChange = (setDataForm: any, e: ChangeEvent) => {
  // console.log(`HandleChange[${e.target.name}]`, e.target.value)
  setDataForm((prev: any) => ({
    ...prev,
    [e.target.name]: e.target.value,
  }));
};

const calSyring = (pull: string, methodName: string) =>{
  let sytext:string = '';
  let chemo_p: number
  chemo_p = parseFloat(pull)
  if(chemo_p) {
    if (chemo_p <= 3.00) {
      sytext = 'Syringe (5) mL';
    } else if (chemo_p > 3.00 && chemo_p <= 8.00) {
      sytext = 'Syringe (10) mL';
    } else if (chemo_p > 8.00 && chemo_p <= 18.00) {
      sytext = 'Syringe (50) mL';
    } else if (chemo_p > 18.00 && chemo_p <= 28.00) {
      sytext = 'Syringe (50) mL';
    } else if (chemo_p > 28.00) {
      sytext = 'Syringe (50) mL';
    } else {
      sytext = 'Syringe (50) mL';
    }
    // syringe 5       <=  3
    // syringe 10      3 <          <= 8
    // syringe 20     8 <          <= 18
    // syringe 30   18 <          <= 28
    // syringe 50    > 28

    if(methodName == 'iv Push'){
      sytext = "Syringe (10) mL";
    }

  }
  return sytext;
}

const calSyringSize2 = (pull: string, methodName: string) =>{
  let sytext:string = '';
  let chemo_p: number
  chemo_p = parseFloat(pull)
  if(chemo_p) {
    if (chemo_p <= 3.00) {
      sytext = 'Syringe (5) mL';
    } else if (chemo_p > 3.00 && chemo_p <= 8.00) {
      sytext = 'Syringe (10) mL';
    } else if (chemo_p > 8.00 && chemo_p <= 18.00) {
      sytext = 'Syringe (30) mL';
    } else if (chemo_p > 18.00 && chemo_p <= 28.00) {
      sytext = 'Syringe (30) mL';
    } else if (chemo_p > 28.00) {
      sytext = 'Syringe (50) mL';
    } else {
      sytext = 'Syringe (50) mL';
    }
    // syringe 5       <=  3
    // syringe 10      3 <          <= 8
    // syringe 20     8 <          <= 18
    // syringe 30   18 <          <= 28
    // syringe 50    > 28

    if(methodName == 'iv Push'){
      sytext = "Syringe (10) mL";
    }

  }
  return sytext;
}

interface MyToken {
  name: string;
  exp: number;
  // whatever else is in the JWT.
}

const RequireAuth = (children: {children: any}) => {
  const { profile } = useContexts();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
      if (profile?.accessToken == null) {
          navigate('/login', {replace: true})
      } else {
          const jwtDecoded = jwtDecode<MyToken>(profile.accessToken);
          if (jwtDecoded.exp * 1000 < new Date().getTime()) {
              localStorage.clear();

              setTimeout(() => {
                  window.location.href = '/login';
              }, 200)
          }
      }
  }, [location]);

  return children.children;
};

export { Alert, OptionSelects, HandleChange, calSyring, RequireAuth,calSyringSize2 };