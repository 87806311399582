import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {useContexts} from "../../context/AppContext";

export default function Setting() {
    const navigate = useNavigate();
    const {profile } = useContexts();

return (
    <Container>
        <Container className="row row-cols-1 row-cols-md-4 mb-3 text-center">
          {(profile?.sub === '0') &&
              <>
              <Container className={"col"}>
            <div className="card mb-3 rounded-3 shadow-sm" onClick={() => { navigate("/setting/medicine");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">ยา</h4>
              </div>
              <div className="card-body">
                <img src={"/images/syringe.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>

          <Container className={"col"}>
            <div className="card mb-3 rounded-3 shadow-sm" onClick={() => { navigate("/setting/scs-chemo");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">น้ำเกลือที่ใช้ผสม</h4>
              </div>
              <div className="card-body">
                <img src={"/images/syringe.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>

          <Container className={"col"}>
            <div className="card mb-4 rounded-3 shadow-sm" onClick={() => { navigate("/setting/scs-gem");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">ตัวทำละลาย</h4>
              </div>
              <div className="card-body">
                <img src={"/images/syringe.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>

          <Container className={"col"}>
            <div className="card mb-4 rounded-3 shadow-sm" onClick={() => { navigate("/setting/medicine-use");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">การใช้ยา</h4>
              </div>
              <div className="card-body">
                <img src={"/images/choose.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>
              </>
          }

          <Container className={"col"}>
            <div className="card mb-4 rounded-3 shadow-sm" onClick={() => { navigate("/setting/person");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">รายชื่อผู้ป่วย</h4>
              </div>
              <div className="card-body">
                <img src={"/images/man.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>
          {(profile?.sub === '0') &&
              <>
              <Container className={"col"}>
            <div className="card mb-4 rounded-3 shadow-sm" onClick={() => { navigate("/setting/prefix");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">คำนำหน้าชื่อ</h4>
              </div>
              <div className="card-body">
                <img src={"/images/man.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>

          <Container className={"col"}>
            <div className="card mb-4 rounded-3 shadow-sm" onClick={() => { navigate("/setting/dept");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">แผนก</h4>
              </div>
              <div className="card-body">
                <img src={"/images/corporation.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>

          <Container className={"col"}>
            <div className="card mb-4 rounded-3 shadow-sm" onClick={() => { navigate("/setting/treatment");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Regimen</h4>
              </div>
              <div className="card-body">
                <img src={"/images/corporation.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>
        </>
      }
            {profile?.role === 'admin' && <Container className={"col"}>
                <div className="card mb-4 rounded-3 shadow-sm" onClick={() => {
                    navigate("/setting/user");
                }} style={{cursor: "pointer"}}>
                    <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">จัดการผู้ใช้</h4>
                    </div>
                    <div className="card-body">
                        <img src={"/images/management.png"} alt="" className="img-fluid" width={"128px"}/>
                    </div>
                </div>
            </Container>
            }
        </Container>
      </Container>
  );
};
