import React, {useRef} from 'react';
import ReactToPrint from 'react-to-print';
import {PrinterFill, Star, StarFill,} from 'react-bootstrap-icons';
import {Button, Col, Row} from 'react-bootstrap';
import {calSyring} from '../functions/utils';
import { format } from 'date-fns';
import th from 'date-fns/locale/th';
import {QRCodeSVG} from 'qrcode.react';
import { OrderTransaction } from '../type';
import Moment from 'react-moment';
import moment from 'moment';

type Props = {};


class ContentStar extends React.Component<Props>  {
    render() {
        const rows = [];
        for (let i = 0; i < 207; i++) {
            rows.push(<StarFill/>);
        }
        const nss2 =[];
        const nss25= [];
        const nss50= [];
        const nss100= [];
        for (let i = 0; i <= 59; i++) {
            nss2.push('  200 ml ');
            nss25.push('  250 ml ');
            nss50.push('  500 ml ');
            if(i <= 44){
                nss100.push('  1000 ml ')
            }
        }
        return (<div className="print-container" style={{minHeight:"", margin: "", padding: "0" }}>
            <Row className={"thsarabunnew pagebreak"} style={{margin: "5px",fontSize:"15px",fontWeight:"500"}}>
                <Col  className={"col-12 px-1"}> &nbsp;</Col>
                <Col  className={"col-12 px-1 mb-2"}> &nbsp;</Col>
                <Col className={"col-12 px-1 fw-bold"} style={{fontSize:"14px"}}>
                    {rows}
                </Col>
            </Row>
            <Row className={"thsarabunnew pagebreak"} style={{margin: "5px",fontSize:"15px"}}>
                <Col  className={"col-12 px-1 mb-2"}> &nbsp;</Col>
                {/*<Col  className={"col-12 px-1"}> &nbsp;</Col>*/}
                <Col className={"col-12 px-1"} style={{fontSize:"14px"}}>
                    <img  src="/images/nss.png" style={{
                        width: "100%",
                    }}/>
                </Col>
            </Row>

        </div>)
    }
}

const BtnPrintStar = () => {
  const componentRef = useRef(null);

  return (
    <div className={""}>
      <ReactToPrint
        trigger={() => <Button variant="primary" size="sm"><PrinterFill />  พิมพ์สติกเกอร์ยาใหม่</Button>}
        content={() => componentRef.current}
        // copyStyles={false}
        pageStyle={`@media print {
                    @page {
                      size:  3.54in 2.75in;               
                    }
                    .pagebreak {
                        clear: both;
                        page-break-after: always;
                    }
                  }`}
      />
      <div className={"hide"} style={{display:"none"}}>
      {/*<div>*/}
        <ContentStar ref={componentRef}/>
      </div>
    </div>
  );
};

export default BtnPrintStar;