import {
  createContext,
  useContext,
  useState,
} from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { Children } from "../type";
import {redirect, useNavigate} from "react-router-dom";

type user = {
  userID: number;
  firstName: string;
  lastName: string;
  role: string;
  site: string;
  sub: string;
  chPass: number;
  accessToken: string;
};

type contextType = {
  profile: user | null;
  login: (userProfile: user) => void;
  logout: () => void;
  isLoad: () => void;
  isLoaded: () => void;
};

const contextDefaultValues: contextType = {
  profile: null,
  login: () => {},
  logout: () => {},
  isLoad: () => {},
  isLoaded: () => {}
};

const context = createContext<contextType>(contextDefaultValues);

export function useContexts() {
  return useContext(context);
}

export function AppProvider({ children }: Children) {
  const [isLoading, setIsLoading] = useState(false);
  const [textLoading, setTextLoading] = useState("Please wait loading...");
  const baseURL = process.env.PUBLIC_URL;
  const navigate = useNavigate();
  const login = (userProfile: user) => {
    const profile = JSON.stringify(userProfile)
    localStorage.setItem('accessToken', userProfile.accessToken);
    localStorage.setItem('profile', profile);
    navigate("/",{replace:true})
  };

  const logout = () => {

    localStorage.clear();
    setTimeout(() => {
      navigate("/login",{replace:true})
    }, 350);
  };

  const isLoad = () => {
    setIsLoading(true);
    setTextLoading("Please wait loading...");
  };

  const isLoaded = () => {
    setIsLoading(false);
  };

  let profile = null;
  const storeUser = localStorage.getItem('profile')
  if(storeUser){
    profile = JSON.parse(storeUser);
  }

  const value = {
    login,
    logout,
    isLoad,
    isLoaded,
    profile
  };

  return (
    <LoadingOverlay active={isLoading} spinner text={textLoading}>
      <context.Provider value={value}>{children}</context.Provider>
    </LoadingOverlay>
  );
}
