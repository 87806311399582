import { useEffect, useState } from "react";
import {Button, Container, Nav, Table} from "react-bootstrap";
import { Pen, PlusCircle, Trash } from "react-bootstrap-icons";
import { apiService } from '../../functions/api-service';
import Swal from 'sweetalert2';
import { Alert } from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import ModalPrefix from '../../components/modalPrefix';
import { Prefix } from "../../type";
import {useNavigate} from "react-router-dom";

export default function SettingPrefix() {
  const { isLoad, isLoaded } = useContexts();
  const [prefixList, setPrefixList] = useState<Array<Prefix>>([]);
  const [search, setSearch] = useState("");
  const filteredItems = prefixList.filter(
    (item) => (item.name && item.name.toString().includes(search))
  );
  const [openFormPrefix, setOpenFormPrefix] = useState<{
    show: boolean;
    data: Prefix | null;
    saved: boolean
  }>({ show: false, data: null, saved: false });
  const {profile } = useContexts();

  useEffect(() => {
    getPrefixList().catch(console.error);
    if((profile?.sub !== '0')){
      navigate("/setting")
    }
  }, []);

  useEffect(() => {
    if(openFormPrefix.saved){
      getPrefixList().catch(console.error);
      setOpenFormPrefix((prev) => ({
        ...prev,
        saved: false,
      }))
    }
}, [openFormPrefix.saved]);

  const getPrefixList = async () => {
    isLoad();
    setPrefixList(await apiService.prefixList({status: '1'}));
    isLoaded();
  };

  const removePrefix = async (data: Prefix) => {
    await Swal.fire({
      title: `ต้องการลบ ${data.name}`,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ปิด",
    }).then(async (result) => {
      if (result.isConfirmed && data.id) {
        const res = await apiService.removePrefix(data.id);
        if (res.success === true) {
          getPrefixList().then();          
        }
        Alert(res.success, res.message);
      }
    });
  };
  const navigate = useNavigate();
  return (
    <Container className={"mw-100"}>
      <Nav variant="tabs" defaultActiveKey="/setting/prefix">
        <Nav.Item>
          <Nav.Link onClick={() => navigate("/setting/person")} >Person</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className={"active"} onClick={() => navigate("/setting/prefix")}  >Prefix</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link onClick={() => navigate("/setting/dept")}  >Departments</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  onClick={() => navigate("/setting/treatment")}  >Regimen</Nav.Link>
        </Nav.Item>
      </Nav>
      <div>
        <h2>รายการคำนำหน้าชื่อ</h2>
        <div  className="mb-2">ค้นหาข้อมูลได้จาก ประเภท และ การใช้หรือเวลา</div>  
        <div className="mb-2 row">
          <div className={"col-10"}>
          <input className={"form-control"} type="text" placeholder="ค้นหา.." onChange={(e) => setSearch(e.target.value)} />
          </div>
          <div className={"col-2 d-grid"}>
            <Button variant="success" onClick={() => setOpenFormPrefix({ show: true, data: null, saved: false })}><PlusCircle /> เพิ่มข้อมูล</Button>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>รหัส</th>
              <th>คำนำหน้า</th>
              <th>แก้ไข</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((r) => (
              <tr>
                <td>{r.id}</td>
                <td>{r.name}</td>
                <td className={"text-center"}>
                  <Button
                    size="sm"
                    onClick={() => setOpenFormPrefix({ show: true, data: r, saved: false })}
                  >
                    <Pen />
                  </Button>{" "}
                  <Button variant="danger" size="sm" onClick={() => removePrefix(r).then()}>
                    <Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ModalPrefix
          show={openFormPrefix.show}
          data={openFormPrefix.data}
          handleClose={() =>
            setOpenFormPrefix((prev) => ({
              ...prev,
              show: false,
            }))
          }
          saved={() =>
            setOpenFormPrefix((prev) => ({
              ...prev,
              saved: true,
            }))}
        />
      </div>
      </Container>
  );
}
