import React, {ReactNode, useEffect, useState} from "react";
import {Button, Container, Nav, Table} from "react-bootstrap";
import {CartCheck, CheckCircle, Pen, PlusCircle, PrinterFill, Recycle, StarFill, Trash} from "react-bootstrap-icons";
import { apiService } from '../../functions/api-service';
import Swal from 'sweetalert2';
import { Alert } from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import {ChangeEvent, Department, OrderDetail, OrderTransaction} from "../../type";
import ModalDept from "../../components/modalDept";
import {format} from "date-fns";
import th from "date-fns/locale/th";
import FilterOrderTran from "../../components/filterOrderTran";
import DatePicker from "react-datepicker";
import {CSVDownload, CSVLink,} from "react-csv";
import moment from "moment";
import {useNavigate} from "react-router-dom";

export default function SynReport1() {
  const { isLoad, isLoaded, profile} = useContexts();
    const navigate = useNavigate();
    useEffect(() => {
        if((profile?.sub !== '0')){
            navigate("/")
        }
    }, []);

  let checkHn = "";

  const statusMachine = [
    { value: "1", lable: "Ready" },
    { value: "2", lable: "Running" },
    { value: "3", lable: "Finished" },
    { value: "4", lable: "Error" },
  ];
  const statusOrder = [
    { value: "1", lable: "รับแล้ว" },
    { value: "2", lable: "เช็คยา" },
    { value: "3", lable: "จ่ายยา" },
    { value: "99", lable: 'Error' },
  ];
  const [orderTranList, setOrderTranList] = useState<Array<OrderTransaction>>([]);

  const [searchOrderTran, setSearchOrderTran] = useState({
    orderStartDate: new Date(),
    orderEndDate: new Date(),
    statusMachine: "",
    statusOrder: "",
  });

  useEffect(() => {
    getOrderTranList().catch(console.error);
  }, [searchOrderTran.orderStartDate,
          searchOrderTran.orderEndDate,
          searchOrderTran.statusMachine,
          searchOrderTran.statusOrder]);

  const getOrderTranList = async () => {
    isLoad();
    setOrderTranList(
        await apiService.orderTranList({type:'report',
          createAt: format(new Date(searchOrderTran.orderStartDate), "yyyy-MM-dd"),
          createEnd: format(new Date(searchOrderTran.orderEndDate), "yyyy-MM-dd"),
          statusMachine: searchOrderTran.statusMachine,
          statusOrder: searchOrderTran.statusOrder,
        }),

    );
    isLoaded();
  };
  const handleChange = ( e: ChangeEvent) => {
    setSearchOrderTran((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };


  const csvData = [
    ["#","ID","วันที่", "ชื่อผู้ป่วย", "HN/AN", "แผนก", "รายการยา", "สารน้ำที่ใช้", "ขนาดยา (mg)", "ปริมาตรยา (ml)",
      "สูตรยา (Regimen)","complete", "error (สาเหตุจาก)", "เวลา (นาที)", "ช่วงน้ำหนัก(Syringe)","ช่วงน้ำหนัก(Medication)", "%ความคลาดเคลื่อน(Syringe)", "%ความคลาดเคลื่อน(Medication)"]
  ];
  // compare data for display and export
  const orderList = [];
  for (let i = 0; i < orderTranList.length; i++) {
    let r = orderTranList[i];
    let chemo = r.chemoPull && parseFloat(r.chemoPull) > 0? r.chemoPull :r.chemoPullGem;
    let teme:any =
        [ (i+1).toString(),
          (r.orderID),
          (r.orderDate ? format(new Date(r.orderDate), "dd-MMM-yyyy", {locale: th}):'-').toString(),
          (r?.person?.fullName),
          (r?.person?.hn),
          (r?.dept),
          (r?.med?.name+" ("+r?.med?.strength+"mg/ "+r?.med?.solvent+"ml)"),
          (r?.scsId && r?.med && r?.med?.scsList.length > 0 && r.med.scsList.find(e => e.id === r.scsId)?.scs?.name),
          (r.perTime && parseFloat(r.perTime) > 0 && parseFloat( r.perTime as string).toFixed(2)),
          (chemo && parseFloat(chemo as string).toFixed(2)),
          (r?.treatmentName),
          "",
          "",
          (((r.orderDetail && r.orderDetail?.length > 0) && r.startAt && r.endAt) && moment(moment(r.endAt).diff(moment(r.startAt))).format("mm.ss")),
          ((r.orderDetail && r.orderDetail?.length > 0) && r.syringeSum ? parseFloat(JSON.parse(r.syringeSum).sumweight).toFixed(2) :'-'),
          // (r.orderDetail && r.medSum ? parseFloat(JSON.parse(r?.medSum).sumweight).toFixed(2) :'-'),
          ((r.orderDetail && r.orderDetail?.length > 0) && r.syrError),
          // (r.orderDetail && r.medError)
          ];

    csvData.push(teme)
  }

  return (
      <>
        <Container className={"mw-100"}>
          <h2>รายงานประจำวัน</h2>
          {/*<div  className="mb-2">ค้นหาข้อมูลได้จาก รหัส และชื่อ</div>*/}
          <div className="mb-2 row bg-primary bg-opacity-25 p-1">
            <div className={"col-3"}>
              <label htmlFor="orderStartDate" className={"form-label"}>
                วันที่ {format(new Date(searchOrderTran.orderStartDate), "yyyy-MM-dd")}
              </label>
              <DatePicker
                  id={"orderStartDate"}
                  name={"orderStartDate"}
                  selected={searchOrderTran.orderStartDate}
                  onChange={(date: Date) =>
                      handleChange({target: {name: "orderStartDate", value: date}})
                  }
                  locale={th}
                  dateFormat={"dd-MM-yyyy"}
                  className={"form-control"}
              />

            </div>
            <div className={"col-3"}>
              <label htmlFor="orderEndDate" className={"form-label"}>
                วันที่สิ้นสุด {format(new Date(searchOrderTran.orderEndDate), "yyyy-MM-dd")}
              </label>
              <DatePicker
                  id={"orderEndDate"}
                  name={"orderEndDate"}
                  selected={searchOrderTran.orderEndDate}
                  onChange={(date: Date) =>
                      handleChange({target: {name: "orderEndDate", value: date}})
                  }
                  locale={th}
                  dateFormat={"dd-MM-yyyy"}
                  className={"form-control"}
              />

            </div>

            <div className={"col-2"}>
              <label htmlFor="statusMachine" className={"form-label"}>
                สถานะ Machine
              </label>
              <select
                  id="statusMachine"
                  name="statusMachine"
                  className="form-select"
                  onChange={(e) =>
                      handleChange({
                        target: {name: "statusMachine", value: e.target.value},
                      })
                  }
              >
                <option key={99}></option>
                {statusMachine.map((r, i) => (
                    <option value={r.value} key={i}>{r.lable}</option>
                ))}
              </select>
            </div>
            <div className={"col-2"}>
              <label htmlFor="statusOrder" className={"form-label"}>
                สถานะ Order
              </label>
              <select
                  id="statusOrder"
                  name="statusOrder"
                  className="form-select"
                  onChange={(e) =>
                      handleChange({
                        target: {name: "statusOrder", value: e.target.value},
                      })
                  }
              >
                <option key={99}></option>
                {statusOrder.map((r, i) => (
                    <option value={r.value} key={i}>{r.lable}</option>
                ))}
              </select>
            </div>
            <div className={"col-2 text-end"}>
              <CSVLink
                  data={csvData}
                  filename={"report-per-day_" + moment(new Date()).format("DD-MM-YYYY") + ".csv"}
                  className="btn btn-primary mt-3"
                  target="_blank"
              >
                Download
              </CSVLink>
            </div>
          </div>

          <Table striped bordered hover responsive={"xl"}>
            <thead className={'center'} style={{textAlign: "center",}}>
            <tr>
              <th rowSpan={2}>#</th>
              <th rowSpan={2}>ID</th>
              <th rowSpan={2}>วันที่</th>
              <th colSpan={3}>ข้อมูลผู้ป่วย</th>
              <th colSpan={5}>ข้อมูลยาเตรียมเคมีบำบัด</th>
              <th colSpan={3}>ผลการเตรียม</th>
              <th colSpan={4}>ความแม่นยำ (Acuuracy)</th>
            </tr>
            <tr>
              <th>ชื่อผู้ป่วย</th>
              <th>HN/AN</th>
              <th>แผนก</th>
              <th>รายการยา</th>
              <th>สารน้ำที่ใช้</th>
              <th>ขนาดยา (mg)</th>
              <th>ปริมาตรยา (ml)</th>
              <th>สูตรยา (Regimen)</th>
              <th>complete</th>
              <th>error (สาเหตุจาก)</th>
              <th>เวลา (นาที)</th>
              <th>ช่วงน้ำหนัก(Syringe)</th>
              {/*<th>ช่วงน้ำหนัก(Medication)</th>*/}
              <th>%ความคลาดเคลื่อน(Syringe)</th>
              {/*<th>%ความคลาดเคลื่อน(Medication)</th>*/}
            </tr>
            </thead>
            <tbody>
            {orderTranList && orderTranList.map((r, i) => (
                <tr>
                  <td> {i + 1}</td>
                  <td> {r.orderID}</td>
                  <td>{r.orderDate && format(new Date(r.orderDate), "dd-MMM-yyyy", {locale: th})}</td>
                  <td>{r?.person?.fullName}</td>
                  <td>{r?.person?.hn}</td>
                  <td>{r?.dept}</td>
                  <td>{r?.med?.name} ({r?.med?.strength} mg/ {r?.med?.solvent} ml)</td>
                  <td>{r?.scsId && r?.med && r?.med?.scsList.length > 0 &&
                      r.med.scsList.find(e => e.id === r.scsId)?.scs?.name}</td>
                  <td>{r.perTime && parseFloat(r.perTime) > 0 && parseFloat( r.perTime as string).toFixed(2)}</td>
                  <td>{r.chemoPull && parseFloat(r.chemoPull) > 0 && parseFloat( r.chemoPull as string).toFixed(2)}
                    {r.chemoPullGem && parseFloat(r.chemoPullGem) > 0 &&  parseFloat(r.chemoPullGem as string).toFixed(2)}</td>
                  <td>{r?.treatmentName}</td>
                  <td></td>
                  <td>{r.error}</td>
                  <td>
                    {((r.orderDetail && r.orderDetail?.length > 0) && r.startAt && r.endAt) && moment(moment(r.endAt).diff(moment(r.startAt))).format("mm.ss")}
                  </td>
                  <td>{(r.orderDetail && r.orderDetail?.length > 0) && r.syringeSum ? parseFloat(JSON.parse(r.syringeSum).sumweight).toFixed(2) :'-'}</td>
                  {/*<td>{r.medSum ? parseFloat(JSON.parse(r?.medSum).sumweight).toFixed(2) :'-'}</td>*/}
                  <td>{(r.orderDetail && r.orderDetail?.length > 0) && r.syrError ? ( isFinite(Number(r.syrError)) ? parseFloat(r.syrError).toFixed(2) :'Error' ): ' '}</td>
                  {/*<td>{r.medError}</td>*/}
                </tr>
            ))}
            </tbody>
          </Table>
        </Container>

      </>
  );
}
