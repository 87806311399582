import { useEffect, useState } from "react";
import { useContexts } from "../context/AppContext";
import {Carousel, Col, Container, Row} from 'react-bootstrap';
import { apiService } from '../functions/api-service';
import { Alert } from '../functions/utils';
import {DrugMac, medicineView} from "../type";


export default function Api() {

    const [data, setData] = useState<medicineView | null>( null );

    useEffect(() => {
        // Fetch data from backend when the component mounts

    }, []);

    const handleSave = async () => {
        if(data){
            const res = await apiService.saveMdicineView( data );
            if ( res.success === true ) {
            }
        }
    }

  return (
      <Container className={"text-center"} style={{height:"100vh",width:"100%",minWidth:"100%",
        backgroundImage: "url(/images/bg_login.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity:"75%"
      }} >
          {'--->'}  ..|.. {'<---'}
      </Container>

  );

}
