import {Fragment, ReactNode, useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {  
  DoorClosed,
  Gear,
  HouseExclamation,
} from "react-bootstrap-icons";
import { useContexts } from "../context/AppContext";
import {Col} from 'react-bootstrap';
import jwtDecode from "jwt-decode";
import {Helmet} from "react-helmet";
import ModalUser from "./modalUser";
import ModalChangPassword from "./modalChangPassword";
import {User} from "../type";

interface MyToken {
  name: string;
  exp: number;
  // whatever else is in the JWT.
}

export default function Layout(children: {children: ReactNode}) {
  const { profile } = useContexts();
  const navigate = useNavigate();
  const location = useLocation().pathname.match(/^\/([^?\/]+)/);
  let path = location ? location : '';
  if(path && path.length > 0){
    path = path[1];
  }
  const { logout } = useContexts();
  const [openFormUser, setOpenFormUser] = useState<{
        show: boolean;
        saved: boolean;
        userId: number;
    }>({ show: false, saved: false, userId: 0 });
    const baseURL = process.env.PUBLIC_URL;

  useEffect(() => {
      if (profile?.accessToken == null) {
          navigate("/login",{replace:true})
      } else {
          const jwtDecoded = jwtDecode<MyToken>(profile.accessToken);
          if (jwtDecoded.exp * 1000 < new Date().getTime()) {
              localStorage.clear();

              setTimeout(() => {
                  navigate("/login",{replace:true})
              }, 200)
          }

          //chang password
          if(profile?.chPass === 1){
              setOpenFormUser({ show: true, saved: false, userId: profile?.userID })
          }
      }
  }, [location]);

    useEffect(() => {
        if(openFormUser.saved){
            setOpenFormUser((prev) => ({
                ...prev,
                saved: false,
            }))
        }
    }, [openFormUser.saved]);

  return (
    <Fragment>
      <Helmet>
        <title>AYA-Pharmacy-Station - {path}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <div className="row p-2">
        <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom"
                style={{
                    backgroundColor:"#568de5",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    // backgroundImage: "linear-gradient(90deg, rgb(86, 141, 229), white)"
                }} >
          <Col>
            <span
              onClick={() => navigate("/")}
              role="button"
              className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
            >
                {/*<img  className="fs-4" style={{width: "15%"}} src="/images/004.jpg" />*/}
                <img  className="fs-4" src={`/images/site${profile?.site}.png`} style={{
                    width: "8%",
                    backgroundColor: "white",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    borderRadius:"20%",
                }}/>


            </span>
          </Col>
            <Col className={"/"}>
                <ul className="nav nav-pills " style={{float: "right"}}>
                    <li className="mx-2 nav-item border border-2 border-bottom-0 border-start-0 border-top-0 ">
                        <span className={'nav-link link-light pb-0'}>
                            {profile?.firstName}{' '}{profile?.lastName}
                        </span>
                        <span className={'nav-link link-light pt-0'}>
                            Site : {profile?.site}{'-'}{profile?.sub}
                        </span>
                    </li>
                    <li className="nav-item">
                <span
                    onClick={() => navigate("/")}
                    role="button"
                    className={`nav-link link-light ${
                        path == "" && "active text-white"
                    }`}
                    aria-current="page"
                >
                  <HouseExclamation className="fs-3 bi d-block mx-auto mb-1"/>
                  Home
                </span>
                        </li>
                        <li className="nav-item">
                <span
                    onClick={() => navigate("/setting")}
                    role="button"
                    className={`nav-link link-light ${
                        path.indexOf("setting") > -1 && "active text-white"
                    }`}
                >
                  <Gear className="fs-3 bi d-block mx-auto mb-1"/>
                  Setting
                </span>
                        </li>
                    {(profile?.sub === '0') && <li className="nav-item">
                <span
                    onClick={() => navigate("/syn")}
                    role="button"
                    className={`nav-link link-light ${
                        path.indexOf("syn") > -1 && "active text-white"
                    }`}
                >
                  <Gear className="fs-3 bi d-block mx-auto mb-1"/>
                  Synchronize
                </span>
                        </li>
                    }
                        <li className="nav-item">
                <span
                    onClick={() => {
                        logout();
                        // navigate("/login");
                    }}
                    role="button"
                    className="nav-link link-light"
                >
                  <DoorClosed className="fs-3 bi d-block mx-auto mb-1"/>
                  SignOut
                </span>
                        </li>
                </ul>
            </Col>


        </header>
      </div>
        {children.children}


        <ModalChangPassword
            show={openFormUser.show}
            userId={openFormUser.userId}
            handleClose={() =>
                setOpenFormUser((prev) => ({
                    ...prev,
                    show: false,
                }))
            }
            saved={() =>
                setOpenFormUser((prev) => ({
                    ...prev,
                    saved: true,
                }))}
            setUserId={(userId: number) =>
                setOpenFormUser((prev) => ({
                    ...prev,
                    userId: userId,
                }))
            }
        />

      <div className="container" style={{ marginTop: 300 }}>
        <footer className="py-3 my-4">
          <ul className="nav justify-content-center border-bottom pb-3 mb-3"></ul>
          <p className="text-center text-muted">AYA © 2022 Company, Inc</p>
        </footer>
      </div>
    </Fragment>
  );
}
