import { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { Pen, PlusCircle, Trash } from "react-bootstrap-icons";
import ModalMedicine from "../../components/modalMedicine";
import Swal from "sweetalert2";
import { apiService } from '../../functions/api-service';
import { Alert } from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import { Medicine } from "../../type";
import BtnPrintSummaryChemo from "../../components/printSummaryChemo";
import BtnPrintStar from "../../components/printStar";

export default function SettingMedicine() {
  const { isLoad, isLoaded } = useContexts();
  const [medList, setMedList] = useState<Array<Medicine>>([]);
  const [search, setSearch] = useState({text: "", productcat: ""});
  const filteredItems = medList.filter(
    (item) =>
      ((item.code && item.code.toString().includes(search.text)) ||
      (item.name && item.name.toString().includes(search.text)) ||
      (item.machineMedName && item.machineMedName.toString().includes(search.text)))
      && (search.productcat && item.productcat === search.productcat)
  );
  const [openFormMedicine, setOpenFormMedicine] = useState<{
    show: boolean;
    data: Medicine | null;
    saved: boolean;
  }>({ show: false, data: null, saved: false });

  useEffect(() => {
    getMedList().catch(console.error);
  }, []);

  useEffect(() => {
    if (openFormMedicine.saved) {
      getMedList().catch(console.error);
      setOpenFormMedicine((prev) => ({
        ...prev,
        saved: false,
      }));
    }
  }, [openFormMedicine.saved]);

  const getMedList = async () => {
    isLoad();
    setMedList(await apiService.medList({status: '1'}));
    isLoaded();
  };

  const removeMed = async (data: Medicine) => {
    await Swal.fire({
      title: "ต้องการลบยา " + data.name,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ปิด",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await apiService.removeMed(data.id);
        if (res.success === true) {
          getMedList().then();          
        }
        Alert(res.success, res.message);
      }
    });
  };

  return (
    <Container className={"mw-100"}>
        <h2>รายการยา</h2>
        <div className="mb-2">
          ค้นหาข้อมูลได้จาก ประเภท หรือ รหัส ชื่อ และ ชื่อที่ใช้ในหุ่นยนต์
        </div>
        <div className="mb-2 row">
          <div className={"col-2"}>
            <select className={"form-select"} onChange={(e) => setSearch((prev) => ({...prev, productcat: e.target.value}))}>
              <option value={""}>ประเภท</option>
              <option value={"1"}>ยา</option>
              <option value={"6"}>น้ำเกลือ</option>
            </select>
          </div>
          <div className={"col-8"}>
            <input
              className={"form-control"}
              type="text"
              placeholder="ค้นหา.."
              onChange={(e) => setSearch((prev) => ({...prev, text: e.target.value}))}
            />
          </div>
          <div className={"col-2 d-grid"}>
            <Button
              variant="success"
              onClick={() =>
                setOpenFormMedicine({ show: true, data: null, saved: false })
              }
            >
              <PlusCircle /> เพิ่มยา
            </Button>
            <BtnPrintStar/>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>รหัส</th>
              <th>ชื่อยา</th>
              <th>ชื่อที่ใช้ในหุ่นยนต์</th>
              <th>Strength</th>
              <th>Volumn</th>
              <th>TMT</th>
              <th>Machime</th>
              <th>แก้ไข</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((r) => (
              <tr>
                <td>{r.code}</td>
                <td>{r.name}</td>
                <td>{r.machineMedName}</td>
                <td>{r.strength}</td>
                <td>{r.solvent}</td>
                <td>{r.tmtid}</td>
                <td>{r.machine?.itemId} {r.machine?.fullName}</td>
                <td className={"text-center"}>
                  <Button
                    size="sm"
                    onClick={() =>
                      setOpenFormMedicine({ show: true, data: r, saved: false })
                    }
                  >
                    <Pen />
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => removeMed(r).then()}
                  >
                    <Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ModalMedicine
          show={openFormMedicine.show}
          data={openFormMedicine.data}
          handleClose={() =>
            setOpenFormMedicine((prev) => ({
              ...prev,
              show: false,
            }))
          }
          saved={() =>
            setOpenFormMedicine((prev) => ({
              ...prev,
              saved: true,
            }))
          }
        />
      </Container>
  );
}
