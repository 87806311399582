import { useEffect, useState } from "react";
import { useContexts } from "../context/AppContext";
import {Carousel, Col, Container, Row} from 'react-bootstrap';
import { apiService } from '../functions/api-service';
import { Alert } from '../functions/utils';


export default function Login() {
  const { login } = useContexts();
  const [username, setUsername] = useState(process.env.NODE_ENV === 'production' ? '' : 'dev');
  const [password, setPassword] = useState(process.env.NODE_ENV === 'production' ? '' : 'dev');

  const signIn = async () => {
    const response = await apiService.signIn(username, password);
    console.log("response", response);
    if (response.success === true) {
      login(response);
    } else {
      Alert(false, response.message);
    }
  };

  function CarouselFadeExample() {
    return (
        <Carousel  slide={false}>
          <Carousel.Item>
            <img
                style={{minHeight:"-webkit-fill-available"}}
                className="d-block w-100"
                src="/images/slide/IMG_3892.jpg"
                alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
                className="d-block w-100"
                src="/images/slide/835A7818.jpg"
                alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
                className="d-block w-100"
                src="/images/slide/IMG_3882.jpg"
                alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
                className="d-block w-100"
                src="/images/slide/IMG_3910.jpg"
                alt="Four slide"
            />
          </Carousel.Item>
        </Carousel>
    );
  }

  return (
      <Container className={"text-center"} style={{height:"100vh",width:"100%",minWidth:"100%",
        backgroundImage: "url(/images/bg_login.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity:"75%"
      }} >
          <Row style={{height:"20%"}}></Row>
          <Row className={"bg-white"} style={{padding:"15px",width:"80%",marginLeft: "auto",marginRight: "auto",border:"2px solid rgb(206 212 217)",borderRadius:"25px"}}>
              {/*border:"2px solid red",borderRadius:"25px"*/}
              <Col className={"bg-white border-gray-500"}  >


                      {CarouselFadeExample()}
              </Col>
              <Col className={"bg-white text-center"}>
                  {/*<Row className={"p-2 text-center"}>*/}
                  {/*    /!*<img  style={{  display: "block",marginLeft: "auto",marginRight: "auto",width: "50%"}} src="/images/004.jpg" />*!/*/}
                  {/*    <img  style={{  display: "block",marginLeft: "auto",marginRight: "auto",width: "50%"}} src="/images/LOGO-MTH-2021.png" />*/}
                  {/*</Row>*/}

                  <Row className={"text-center text-primary fw-bold pt-5 bg-white"}>
                      <h1>AYA</h1>
                      <p>Chemotherapy Compounding Robot</p>
                  </Row>
                  <Row>
                      <Container
                          style={{ width: "100%", maxWidth: 430, padding: 15, textAlign: "center" }}
                      >

                          <form
                              onSubmit={(e) => {
                                  e.preventDefault();
                                  signIn().then();
                              }}
                              className="mt-3"
                          >
                              <div className="form-floating">
                                  <input
                                      value={username}
                                      required
                                      type="text"
                                      className="form-control"
                                      id="floatingInput"
                                      onChange={(e) => setUsername(e.target.value)}
                                  />
                                  <label htmlFor={"floatingInput"}>ชื่อผู้ใข้</label>
                              </div>
                              <div className="form-floating mb-3">
                                  <input
                                      value={password}
                                      required
                                      type="password"
                                      className="form-control mt-2"
                                      id="floatingPassword"
                                      onChange={(e) => setPassword(e.target.value)}
                                  />
                                  <label htmlFor="floatingPassword">รหัสผ่าน</label>
                              </div>

                              <button className="w-100 btn btn-lg btn-primary" type="submit">
                                  เข้าใช้งาน
                              </button>
                          </form>
                      </Container>
                  </Row>
              </Col>
          </Row>

      </Container>

  );

}
