import React, { useEffect, useState } from "react";
import {Button, Container, Nav, Table} from "react-bootstrap";
import {
  ArrowDownUp,
  CheckCircleFill, Eye,
  Pen,
  PersonCheckFill,
  PersonFillDash,
  PlusCircle,
  Trash,
  XCircleFill
} from "react-bootstrap-icons";
import { apiService } from '../../functions/api-service';
import Swal from 'sweetalert2';
import {Alert, OptionSelects} from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import {Department, medicineView, MedUse, OptionSelect, OrderTransaction, Person, Prefix} from "../../type";
import ModalDept from "../../components/modalDept";
import {format} from "date-fns";
import th from "date-fns/locale/th";
import DatePicker from "react-datepicker";
import ModalOrderTransaction from "../../components/modalOrderTran";
import {useNavigate} from "react-router-dom";
import * as sweetalert2 from "sweetalert2";

export default function SynMedicineView() {
  const { isLoad, isLoaded } = useContexts();
  const [medList, setMedList] = useState<Array<medicineView>>([]);
  const [search, setSearch] = useState("");
  const [prefixList, setPrefixList] = useState<Array<Prefix>>([]);
  const filteredItems = medList.filter(
      (item) =>  (
          ((item.id && item.id.toString().includes(search)) ||
          (item.hn && item.hn.toString().includes(search)) ||
          (item.itemName && item.itemName.toString().includes(search)) ||
          (item.name && item.name.toString().includes(search))))
  );

  const [openFormOrderTran, setOpenFormOrderTran] = useState<{
    show: boolean;
    data: OrderTransaction | null;
    saved: boolean;
  }>({ show: false, data: null, saved: false });
  const navigate = useNavigate();
  const {profile } = useContexts();
  useEffect(() => {
    if((profile?.sub !== '0')){
      navigate("/")
    }
  }, []);

  const setInitOpenOrderTran = async (r:medicineView) => {
    const dataInint:OrderTransaction | null = {};
    const dataPerson:Person | null = {
      status:true,
      id:0,
      prefix:{id:0,name:'',status:true},
      hn:'',
      firstName:'',
      fullName:'',
      lastName:''};

    const dataDep:Department = {} as Department
    //check exit HN
    const  person  =await apiService.personList(r.hn.toString())
    //if exit innit
    if(person.length > 0 && person[0].hn){
      dataInint.person = person[0]
    }else {
      //if not exit create New HN
      dataPerson.hn = r.hn;

      const nameParts = r.name.split(" ");

      // r.name
      const filteredPrefixItem = prefixList.filter(
          (item) => (item.name && (
              item.name.toString().includes(nameParts[0].substring(0, 3)) ||
              item.name.toString().includes(nameParts[0].substring(0, 4)) ||
              item.name.toString().includes(nameParts[0].substring(0, 5)) ||
              item.name.toString().includes(nameParts[0].substring(0, 8))
          ))
      );
      const names = nameParts[0].split(filteredPrefixItem[0].name)
      dataPerson.prefix = filteredPrefixItem[0];
      dataPerson.firstName = names[names.length- 1];
      dataPerson.lastName = nameParts[nameParts.length - 1];
      dataPerson.fullName = r.name;
      const res = await apiService.savePerson( dataPerson);

      dataInint.person = res.data;
    }


    //check exit Medication
    const  med  =await apiService.medList({tmtid:r.tmtid})
    if(med && med.length > 0 && med[0].id ){
      dataInint.med  = med[0];

      const nss = (med[0]?.scsList.length > 0 && med[0].scsList.find(e => e.scs?.code === ((r.diluent.replaceAll(' ',''))+' '+r.diluentVolume).toString()))
      dataInint.scsId = nss ? nss?.id :0;
    }
    //if exit init for save data





    if(r.ward){

    }

    //check exit dept
    const  dept  =await apiService.deptList({status: '1',name:r.ward})
    //if exit innit
    if(dept.length > 0 && dept[0].name){
      dataInint.dept = dept[0].name;
    }else {
      //if not exit create New HN

      dataDep.code = (Math.floor(Math.random() * (20000 - 19950 + 1)) + 19950).toString();
      dataDep.name = r.ward;

      const res = await apiService.saveDept( dataDep);
      dataInint.dept = res.data.name;
    }



    dataInint.amount1 = parseInt(r.qty);
    dataInint.perDay = parseInt(r.mixBott);
    dataInint.perTime = (r.dose);
    dataInint.viewId = r.id;
    dataInint.orderDate = r.docDt ? new Date(r.docDt) : new Date();
    console.log((r.diluent.replaceAll(' ','')))
    console.log(((r.diluent.toString().trim())+' '+r.diluentVolume).toString())


    // dataInint.method = ;// Check and initialize 'method'
      const method = await apiService.medUseList({name: r.route, status: '1', type: 'วิธีใช้ยา' });
      dataInint.method = method.length > 0 && method[0].id ? method[0] : await createMedUse(r.route, 'วิธีใช้ยา');
    //
    //   // Check and initialize 'time'
      const time = await apiService.medUseList({name: r.interval,status: '1', type: 'เวลาที่ใช้' });
      dataInint.time = time.length > 0 && time[0].id ? time[0] : await createMedUse(r.interval, 'เวลาที่ใช้');

    setOpenFormOrderTran({
      show: true,
      data: dataInint,
      saved: false,
    })
  }
  async function createMedUse(name:string, type:string) {
    const dataMedUse:MedUse = {} as MedUse
    dataMedUse.code = name;
    dataMedUse.name = name;
    dataMedUse.type = type;
    dataMedUse.mainCalculate= '0'
    const res = await apiService.saveMedUse(dataMedUse);
    return res.data;
  }


  const getPrefix  = async () => {
    setPrefixList( await apiService.prefixList({ status: "1" }) );
  };

  const [searchOrder, setSearchOrder] = useState({orderDate: new Date(),});

  useEffect(() => {
    getMedList().catch(console.error);
    getPrefix().then()
  }, [searchOrder.orderDate]);


  const getMedList = async () => {
    isLoad();
    setMedList(await apiService.medicineViewList({
      docDt: format(new Date(searchOrder.orderDate), "yyyy-MM-dd"),
    }));
    isLoaded();
  };






  const removeDrug = async (data: medicineView,status:string) => {
    await Swal.fire({
      title: `ต้องการแก้ไขข้อมูล  ${data.name}`,
      showCancelButton: true,
      confirmButtonText: "แก้ไข",
      cancelButtonText: "ปิด",
    }).then(async (result) => {
      if (result.isConfirmed && data.id) {
        const res = await apiService.removeMedicineView(data.id,status);
        if (res.success) {
          getMedList().then();
        }
        Alert(res.success, res.message);
      }
    });
  };

  return (
      <>
     <Container className={"mw-100"}>
      <div>
        <h2>รายการยา Medicine View</h2>
        <div className="mb-2 row">
          <div className={"col-3"}>

            <label htmlFor="orderStartDate" className={"form-label"}>
              วันที่ {format(new Date(searchOrder.orderDate), "yyyy-MM-dd")}
            </label>
            <DatePicker
                id={"orderStartDate"}
                name={"orderStartDate"}
                selected={searchOrder.orderDate}
                onChange={(date: Date) =>
                    setSearchOrder({orderDate:date})
                }
                locale={th}
                dateFormat={"dd-MM-yyyy"}
                className={"form-control"}
            />

          </div>

          <div className={"col-7"}>
            <div className="mb-2">ค้นหาข้อมูลได้จาก รหัส และชื่อ</div>
            <input className={"form-control"} type="text" placeholder="ค้นหา.."
                   onChange={(e) => setSearch(e.target.value)}/>
          </div>
          <div className={"col-2 d-grid"}>
            {/*<Button variant="success"><PlusCircle/> เพิ่มข้อมูล Online</Button>*/}
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>ID</th>
            <th>hn</th>
            <th>name</th>
            <th>itemName</th>
            <th>ward</th>
            <th>diluent</th>
            <th>diluentVolume</th>
            <th>TMTID</th>
            <th>dose</th>
            <th>qty</th>
            <th>chemoVolume</th>
            <th>docDt</th>
            <th>mixDate</th>
            <th>route</th>
            <th>interval</th>
            <th>แก้ไข</th>
          </tr>
          </thead>
          <tbody>
          {filteredItems.sort((a, b) => b.id - a.id).map((r) => (
              <tr>
                <td>
                  {r.id}
                  <Button
                      size="sm"
                      onClick={() => Swal.fire({
                        text: `${JSON.stringify(r, null, 10)}`,
                        confirmButtonText: 'close',
                      })
                      }
                  >
                    <Eye/>
                  </Button>
                </td>

                <td>{r.hn}</td>
                <td>{r.name}</td>
                <td>{r.itemName}</td>
                <td>{r.ward}</td>
                <td>{r.diluent}</td>
                <td>{r.diluentVolume}</td>
                <td>{r.tmtid}</td>
                <td>{r.dose}</td>
                <td>{r.qty}</td>
                <td>{r.chemoVolume}</td>
                <td>{r.docDt && format(new Date(r.docDt), "dd MMM yyyy", {locale: th}) + ' ' + r.docDt.toString().slice(11, 16)}</td>
                <td>{r.mixDate && format(new Date(r.mixDate), "dd MMM yyyy", {locale: th}) + ' ' + r.mixDate.toString().slice(11, 16)}</td>
                <td>{r.route}</td>
                <td>{r.interval}</td>
                <td className={"text-center"}>
                  <Button
                      size="sm"
                      onClick={() => setInitOpenOrderTran(r)}
                  >
                    <ArrowDownUp/>
                  </Button>

                  {" "}
                  {/*{r.status == '1' ?*/}
                  {/*    (<Button variant="success" size="sm" onClick={() => removeDrug(r, '0').then()}>*/}
                  {/*  <CheckCircleFill/>*/}
                  {/*</Button>) :*/}
                  {/*    (<Button variant="danger" size="sm" onClick={() => removeDrug(r, '1').then()}>*/}
                  {/*  <XCircleFill/>*/}
                  {/*</Button>)*/}
                  {/*}*/}

                </td>
              </tr>
          ))}
          </tbody>
        </Table>
      </div>
      </Container>

      <ModalOrderTransaction
          show={openFormOrderTran.show}
          data={openFormOrderTran.data}
          showError={false}
          handleClose={() =>
              setOpenFormOrderTran((prev) => ({
                ...prev,
                show: false,
              }))
          }
          saved={() =>
              setOpenFormOrderTran((prev) => ({
                ...prev,
                saved: true,
              }))
          }
      />
      </>
  );
}
