import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { apiService } from '../functions/api-service';
import { Alert, HandleChange, OptionSelects } from '../functions/utils';
import { ChangeEvent, Prefix } from '../type';

type Props = {
  show: boolean;
  data: Prefix | null;
  handleClose: () => void;
  saved: () => void;
};

export default function ModalPrefix ( { show, data, handleClose, saved }: Props ) {
  let IsNew = true;
  const [dataForm, setDataForm] = useState<Prefix | null>( null );
  const handleChange = (e: ChangeEvent) => HandleChange(setDataForm, e);

  const handleSave = async () => {
    console.log( 'dataForm', dataForm )
    if(dataForm){
      const res = await apiService.savePrefix( dataForm );
      if ( res.success ) {
        saved();
        handleClose();
      }
      Alert(res.success, res.message);
    }    
  }

  useEffect(() => {
    if ( show ) {
      setDataForm( data );
      console.log( 'data', data );
      IsNew = !data?.id;
    } else {
      setDataForm( null );
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{!dataForm?.id ? `เพิ่มรายการ` : `แก้ไข ${dataForm?.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className={"row row-cols"} onSubmit={( e ) => {
            e.preventDefault();
            handleSave().then();
          }}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>คำนำหน้า</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="รุบุคำนำหน้า"
                onChange={handleChange}
                name={"name"}
                value={dataForm?.name}
              />
            </Form.Group>
            
            <div className="w-100 text-end">
              <Button variant="secondary" size="lg" onClick={handleClose}>
                ปิด
              </Button>{" "}
              <Button variant="primary" type="submit" size="lg">
                บันทึก
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>    
    </>
  );
}
