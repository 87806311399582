import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {useContexts} from "../../context/AppContext";
import {useEffect} from "react";

export default function Syn() {
    const navigate = useNavigate();
    const {profile } = useContexts();
    useEffect(() => {
      if((profile?.sub !== '0')){
        navigate("/")
      }
    }, []);
return (
    <Container>
        <Container className="row row-cols-1 row-cols-md-4 mb-3 text-center">
          <Container className={"col"}>
            <div className="card mb-3 rounded-3 shadow-sm" onClick={() => { navigate("/syn/medicine");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">จัดการข้อมูลยาหุ่นยนต์</h4>
              </div>
              <div className="card-body">
                <img src={"/images/gear.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>


          {(profile?.role === 'admin' || profile?.site === '2') &&  <Container className={"col"}>
            <div className="card mb-3 rounded-3 shadow-sm" onClick={() => { navigate("/syn/medicineView");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">จัดการข้อมูลยา (View)</h4>
              </div>
              <div className="card-body">
                <img src={"/images/syringe.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>
          }


          <Container className={"col"}>
            <div className="card mb-3 rounded-3 shadow-sm" onClick={() => { navigate("/syn/report");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">รายงาน</h4>
              </div>
              <div className="card-body">
                <img src={"/images/seo-report.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>

          <Container className={"col"}>
            <div className="card mb-3 rounded-3 shadow-sm" onClick={() => { navigate("/syn/report1");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">รายงานประจำวัน </h4>
              </div>
              <div className="card-body">
                <img src={"/images/seo-report.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>

          <Container className={"col"}>
            <div className="card mb-3 rounded-3 shadow-sm" onClick={() => { navigate("/syn/report2");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">รายงานประจำเดือน</h4>
              </div>
              <div className="card-body">
                <img src={"/images/seo-report.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>

          <Container className={"col"}>
            <div className="card mb-3 rounded-3 shadow-sm" onClick={() => { navigate("/syn/report3");}} style={{ cursor: "pointer" }}>
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">รายงานตามชนิดของยา และสูตรยา</h4>
              </div>
              <div className="card-body">
                <img src={"/images/seo-report.png"} alt="" className="img-fluid" width={"128px"} />
              </div>
            </div>
          </Container>

        </Container>
      </Container>
  );
};
