import { useEffect, useState } from "react";
import {Button, Container, Nav, Table} from "react-bootstrap";
import {
  CheckCircleFill,
  Pen,
  PersonCheckFill,
  PersonFillDash,
  PlusCircle,
  Trash,
  XCircleFill
} from "react-bootstrap-icons";
import { apiService } from '../../functions/api-service';
import Swal from 'sweetalert2';
import { Alert } from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import {Department, DrugMac} from "../../type";
import ModalDept from "../../components/modalDept";
import ModalDrugMac from "../../components/modalDrugMac";
import {useNavigate} from "react-router-dom";

export default function SynMedicine() {
  const { isLoad, isLoaded } = useContexts();
  const { profile } = useContexts();
  const [medList, setMedList] = useState<Array<DrugMac>>([]);
  const [search, setSearch] = useState("");
  const filteredItems = medList.filter(
      (item) =>  (
          (item.id && item.id.toString().includes(search)) ||
          (item.itemId && item.itemId.toString().includes(search)) ||
          (item.fullName && item.fullName.toString().includes(search)))
  );
  const [openFormDrug, setOpenFormDrug] = useState<{
    show: boolean;
    data: DrugMac | null;
    saved: boolean
  }>({ show: false, data: null, saved: false });

  const navigate = useNavigate();
  useEffect(() => {
    getMedList().catch(console.error);
    if((profile?.sub !== '0')){
      navigate("/")
    }
  }, []);


  useEffect(() => {
    if(openFormDrug.saved){
      getMedList().catch(console.error);
      setOpenFormDrug((prev) => ({
        ...prev,
        saved: false,
      }))
    }
}, [openFormDrug.saved]);

  const getMedList = async () => {
    isLoad();
    setMedList(await apiService.drugMacList());
    isLoaded();
  };

  const removeDrug = async (data: DrugMac,status:string) => {
    await Swal.fire({
      title: `ต้องการแก้ไขข้อมูล  ${data.fullName}`,
      showCancelButton: true,
      confirmButtonText: "แก้ไข",
      cancelButtonText: "ปิด",
    }).then(async (result) => {
      if (result.isConfirmed && data.itemId) {
        const res = await apiService.removeDrugMac(data.itemId,status);
        if (res.success) {
          getMedList().then();
        }
        Alert(res.success, res.message);
      }
    });
  };

  const updateDrug = async (site: string | undefined, ) => {
    isLoad();
    const res = await apiService.updateDrugMac(site);
    getMedList().catch(console.error);
    isLoaded();
  };

  return (
    <Container className={"mw-100"}>
      <div>
        <h2>รายการยาหุ่นยนต์</h2>
        <div  className="mb-2">ค้นหาข้อมูลได้จาก รหัส และชื่อ</div>
        <div className="mb-2 row">
          <div className={"col-10"}>
            <input className={"form-control"} type="text" placeholder="ค้นหา.." onChange={(e) => setSearch(e.target.value)} />
          </div>
          <div className={"col-2 d-grid"}>
            <Button variant="success" onClick={() => updateDrug(profile?.site).then() }><PlusCircle /> เพิ่มข้อมูล Online</Button>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>ID</th>
            <th>รหัส</th>
            <th>Short Name</th>
            <th>Full Name</th>
            <th>Volume/Mg</th>
            <th>TMTID</th>
            <th>Ready to Use</th>
            <th>Gravity|Density</th>
            <th style={{width:"200px"}}>Picture</th>
            <th>แก้ไข</th>
          </tr>
          </thead>
          <tbody>
          {filteredItems.map((r) => (
              <tr>
                <td>{r.id}</td>
                <td>{r.itemId}</td>
                <td>{r.shortName}</td>
                <td>{r.fullName}</td>
                <td>{r.volume}/{r.mass}</td>
                <td>{r.tmtid} </td>
                <td className="center">{r.readyUse && r.readyUse == '1'? (<Button variant="success" size="sm" >
                  <CheckCircleFill/>
                </Button>) : (<Button variant="danger" size="sm" >
                  <XCircleFill/>
                </Button>)}</td>
                <td>{r.gravity} | {r.density}</td>
                <td>
                  <img src={`https://mth.ayapharma.tech/sync_backend/api/v0/download/images/${r.imageName}.png`}
                       alt={r.imageName} style={{
                    width: "75%",
                  }}/></td>
                <td className={"text-center"}>
                  <Button
                      size="sm"
                      onClick={() => setOpenFormDrug({show: true, data: r, saved: false})}
                  >
                    <Pen/>
                  </Button>{" "}
                  {r.status == '1' ? (<Button variant="success" size="sm" onClick={() => removeDrug(r, '0').then()}>
                    <CheckCircleFill/>
                  </Button>) : (<Button variant="danger" size="sm" onClick={() => removeDrug(r, '1').then()}>
                    <XCircleFill/>
                  </Button>)}

                </td>
              </tr>
          ))}
          </tbody>
        </Table>
        <ModalDrugMac
            show={openFormDrug.show}
            data={openFormDrug.data}
            handleClose={() =>
                setOpenFormDrug((prev) => ({
                  ...prev,
                  show: false,
                }))
            }
            saved={() =>
                setOpenFormDrug((prev) => ({
                  ...prev,
                  saved: true,
                }))}
        />
      </div>
      </Container>
  );
}
