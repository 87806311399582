import { useEffect, useState } from "react";
import {Badge, Button, Container, Nav, Table} from "react-bootstrap";
import { Pen, PersonCheckFill, PersonFillAdd, PersonFillDash } from "react-bootstrap-icons";
import { apiService } from '../../functions/api-service';
import Swal from 'sweetalert2';
import { Alert } from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import ModalPerson from '../../components/modalPerson';
import { Person, SetAllNullable } from "../../type";
import {useNavigate} from "react-router-dom";
import ReactPaginate from "react-paginate";

export default function SettingPerson() {
  const { isLoad, isLoaded } = useContexts();
  const [personList, setPersonList] = useState<Array<Person>>([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1); // TODO not use
  const filteredItems = personList.filter(
    (item) => (item.fullName && item.fullName.toString().includes(search))
        || (item.hn && item.hn.toString().includes(search))
        || (item.dept?.name && item.dept.name.toString().includes(search))
  );
  const [openFormPerson, setOpenFormPerson] = useState<{
    show: boolean;
    data: Person | null;
    saved: boolean;
    hn: string;
  }>({ show: false, data: null, saved: false, hn: "" });

  useEffect(() => {
    getPersonList().catch(console.error);
  }, []);

  useEffect(() => {
    if(openFormPerson.saved){
      getPersonList().catch(console.error);
      setOpenFormPerson((prev) => ({
        ...prev,
        saved: false,
      }))
    }
}, [openFormPerson.saved]);

  const getPersonList = async () => {
    isLoad();
    setPersonList(await apiService.personList(search, page));
    isLoaded();
  };

  const removePerson = async (data: Person) => {
    await Swal.fire({
      title: `ต้องการปิดการใช้งาน HN: ${data.hn}`,
      html: `<h5>${data.fullName}</h5>`,
      showCancelButton: true,
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await apiService.removePerson(data.hn);
        if (res.success === true) {
            res.message ='บันทึกข้อมูลเรียบร้อย'
          getPersonList().then();          
        }
        Alert(res.success, res.message);
      }
    });
  };

  const restorePerson =async (data: Person) => {
    data.status = true;
    const res = await apiService.savePerson( data );
    if ( res.success === true ) {
      getPersonList().then();  
    }
    Alert(res.success, res.message);
  }

  const navigate = useNavigate();

  // Number of items to display per page
  const itemsPerPage = 100;

  // Calculate the total number of pages based on the data and itemsPerPage
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  // Current page state
  const [currentPage, setCurrentPage] = useState(0);

  // Slice the data to display the items for the current page
  const currentItems = filteredItems.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
  );
// Handle page change
    const handlePageChange = ({ selected }: { selected: number }) => {
        setCurrentPage(selected);
    };
    const {profile } = useContexts();

  return (
      <Container className={"mw-100"}>
        <Nav variant="tabs" defaultActiveKey="/setting/person">
          <Nav.Item>
            <Nav.Link className={"active"} onClick={() => navigate("/setting/person")} >Person</Nav.Link>
          </Nav.Item>
            {(profile?.sub === '0') && <>
                <Nav.Item>
                <Nav.Link onClick={() => navigate("/setting/prefix")}  >Prefix</Nav.Link>
            </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={() => navigate("/setting/dept")}  >Departments</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link  onClick={() => navigate("/setting/treatment")}  >Regimen</Nav.Link>
                </Nav.Item>
            </>
            }

        </Nav>
        <div  className="tab-content" >
          <h2>รายการผู้ป่วย</h2>
                 <div  className="mb-2">ค้นหาข้อมูลได้จาก hn ชื่อ และ นามสกุล</div>
                 <div className="mb-2 row">
                   <div className={"col-10"}>
                   <input className={"form-control"} type="text" placeholder="ค้นหา.."
                          onChange={(e) => setSearch(e.target.value)} />
                   </div>
                   <div className={"col-2 d-grid"}>
                     <Button variant="success" onClick={() => setOpenFormPerson({ show: true, data: null, saved: false, hn: "" })}><PersonFillAdd /> เพิ่มข้อมูล</Button>
                   </div>
                 </div>
                 <Table striped bordered hover>
                   <thead>
                   <tr>
                       <th>HN</th>
                       <th>ชื่อ</th>
                       <th>สกุล</th>
                       {/*<th>แผนก</th>*/}
                       <th>สถานะ</th>
                       <th>แก้ไข</th>
                     </tr>
                 </thead>
                   <tbody>
                   {currentItems.length > 0 && currentItems.map((r) => (
                      <tr>
                        <td>{r.hn}</td>
                        <td>{r.firstName}</td>
                        <td>{r.lastName}</td>
                        {/*<td>{r.dept?.name}</td>*/}
                        <td>
                            <Badge pill bg={r.status ?'success':'danger'} >{r.status ?'ใช้งาน':'ยกเลิก'}</Badge>
                        </td>
                        <td className={"text-center"}>
                          <Button
                            size="sm"
                            onClick={() => setOpenFormPerson({ show: true, data: r, saved: false, hn: r.hn })}
                          >
                            <Pen />
                          </Button>{" "}
                          {r.status ? (<Button variant="success" size="sm" onClick={() => removePerson(r).then()}>
                            <PersonCheckFill />
                          </Button>) : (<Button variant="danger" size="sm" onClick={() => restorePerson(r).then()}>
                            <PersonFillDash />
                          </Button>)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

            <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination justify-content-center'}
                activeClassName={'active'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
            />
                <ModalPerson
                  show={openFormPerson.show}
                  data={openFormPerson.data}
                  handleClose={() =>
                    setOpenFormPerson((prev) => ({
                      ...prev,
                      show: false,
                    }))
                  }
                  saved={() =>
                    setOpenFormPerson((prev) => ({
                      ...prev,
                      saved: true,
                    }))}
                    setHn={(hn: string) =>
                      setOpenFormPerson((prev) => ({
                        ...prev,
                        hn: hn,
                      }))
                    }
                />

        </div>
      </Container>
  );
}
