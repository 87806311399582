const TH_TO_EN_MAPPING: Record<string, string> = {
  "-": "`",
  "ๅ": "1",
  "/": "2",
  "_": "3",
  "ภ": "4",
  "ถ": "5",
  "ุ": "6",
  "ึ": "7",
  "ค": "8",
  "ต": "9",
  "จ": "0",
  "ข": "-",
  "ช": "=",
  "ๆ": "q",
  "ไ": "w",
  "ำ": "e",
  "พ": "r",
  "ะ": "t",
  "ั": "y",
  "ี": "u",
  "ร": "i",
  "น": "o",
  "ย": "p",
  "บ": "[",
  "ล": "]",
  "ฟ": "a",
  "ห": "s",
  "ก": "d",
  "ด": "f",
  "เ": "g",
  "้": "h",
  "่": "j",
  "า": "k",
  "ส": "l",
  "ว": ";",
  "ง": "'",
  "ผ": "z",
  "ป": "x",
  "แ": "c",
  "อ": "v",
  "ิ": "b",
  "ื": "n",
  "ท": "m",
  "ม": ",",
  "ใ": ".",
  "ฝ": "/",
  "๐": "Q",
  '"': "W",
  "ฎ": "E",
  "ฑ": "R",
  "ธ": "T",
  "ํ": "Y",
  "๊": "U",
  "ณ": "I",
  "ฯ": "O",
  "ญ": "P",
  "ฐ": "{",
  ",": "}",
  "ฤ": "A",
  "ฆ": "S",
  "ฏ": "D",
  "โ": "F",
  "ฌ": "G",
  "็": "H",
  "๋": "J",
  "ษ": "K",
  "ศ": "L",
  "ซ": ":",
  ".": '"',
  "ฅ": "|",
  "(": "Z",
  ")": "X",
  "ฉ": "C",
  "ฮ": "V",
  "ฺ": "B",
  "์": "N",
  "?": "M",
  "ฒ": "<",
  "ฬ": ">",
  "ฦ": "?",
  " ": " ",
  "ู": "^",

};

const EN_TO_TH_MAPPING: Record<string, string> = {
  "`": "-",
  "1": "ๅ",
  "2": "/",
  "3": "_",
  "4": "ภ",
  "5": "ถ",
  "6": "ุ",
  "7": "ึ",
  "8": "ค",
  "9": "ต",
  "0": "จ",
  "-": "ข",
  "=": "ช",
  "q": "ๆ",
  "w": "ไ",
  "e": "ำ",
  "r": "พ",
  "t": "ะ",
  "y": "ั",
  "u": "ี",
  "i": "ร",
  "o": "น",
  "p": "ย",
  "[": "บ",
  "]": "ล",
  "a": "ฟ",
  "s": "ห",
  "d": "ก",
  "f": "ด",
  "g": "เ",
  "h": "้",
  "j": "่",
  "k": "า",
  "l": "ส",
  ";": "ว",
  "'": "ง",
  "z": "ผ",
  "x": "ป",
  "c": "แ",
  "v": "อ",
  "b": "ิ",
  "n": "ื",
  "m": "ท",
  ",": "ม",
  ".": "ใ",
  "/": "ฝ",
  "Q": "๐",
  "W": '"',
  "E": "ฎ",
  "R": "ฑ",
  "T": "ธ",
  "Y": "ํ",
  "U": "๊",
  "I": "ณ",
  "O": "ฯ",
  "P": "ญ",
  "{": "ฐ",
  "}": ",",
  "A": "ฤ",
  "S": "ฆ",
  "D": "ฏ",
  "F": "โ",
  "G": "ฌ",
  "H": "็",
  "J": "๋",
  "K": "ษ",
  "L": "ศ",
  ":": "ซ",
  '"': ".",
  "|": "ฅ",
  "Z": "(",
  "X": ")",
  "C": "ฉ",
  "V": "ฮ",
  "B": "ฺ",
  "N": "์",
  "M": "?",
  "<": "ฒ",
  ">": "ฬ",
  "?": "ฦ",
  " ": " ",
  "^": "ู",
};

enum Language {
  Thai = "Thai",
  English = "English",
  Unknown = "Unknown",
}

function detectLanguage(text: string): Language {
  let hasThaiCharacters = false;
  let hasEnglishCharacters = false;

  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    if (TH_TO_EN_MAPPING[char]) {
      hasThaiCharacters = true;
    } else if (EN_TO_TH_MAPPING[char]) {
      hasEnglishCharacters = true;
    }
    if (hasThaiCharacters && hasEnglishCharacters) {
      return Language.Unknown;
    }
  }

  if (hasThaiCharacters) {
    return Language.Thai;
  } else if (hasEnglishCharacters) {
    return Language.English;
  } else {
    return Language.Unknown;
  }
}

function convertTextToEnglishQwerty(text: string,site:string | undefined): string {
  let result = "";
  let start = "";
  let end = "";
  let s = 0;
  let e = 0;
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    const convertedChar = TH_TO_EN_MAPPING[char] || char;

    if(site){
      // start = (char == convertedChar && start != '*' && char !=' ' && char ? '*':'')

      start = text[i-1] && (!TH_TO_EN_MAPPING[text[i-1]] && TH_TO_EN_MAPPING[char]) ? '*':'';
      end =text[i+1]  && (!TH_TO_EN_MAPPING[text[i+1]] && TH_TO_EN_MAPPING[char]) ? '*':'';

      if(end !=''){
        end = !TH_TO_EN_MAPPING[text[i+1]] &&  TH_TO_EN_MAPPING[text[i-1]] ? '*':''

      }
      if(start !=''){
        start = TH_TO_EN_MAPPING[text[i+1]] &&  !TH_TO_EN_MAPPING[text[i-1]] ? '*':' '

      }

      if(i == 0 ){
        start = TH_TO_EN_MAPPING[char] ? '*': ''
      }


      result += (start+end+convertedChar ) + (TH_TO_EN_MAPPING[char] && text.length == i+1 ? '*':'') ;
    }else {

      result += convertedChar;
    }
  }
  return result;
}

function convertTextToThaiQwerty(text: string): string {
  const language = detectLanguage(text);
//   if (language === Language.Thai) {
//     return text;
//   }

  let result = "";
  for (let i = 0; i < text.length; i++) {
      const char = text[i];

    const convertedChar = EN_TO_TH_MAPPING[char] || char;
    result += convertedChar;
  }
  return result;
}

export { convertTextToThaiQwerty, convertTextToEnglishQwerty,detectLanguage };
