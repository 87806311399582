import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { apiService } from '../functions/api-service';
import { Alert, HandleChange } from '../functions/utils';
import { ChangeEvent, MedUse } from '../type';

type Props = {
  show: boolean;
  data: MedUse | null;
  handleClose: () => void;
  saved: () => void;
};

export default function ModalMedicineUse ( { show, data, handleClose, saved }: Props ) {
  let IsNew = true;
  const [dataForm, setDataForm] = useState<MedUse | null>( null );
  const handleChange = (e: ChangeEvent) => HandleChange(setDataForm, e);

  const handleSave = async () => {
    if(dataForm){
      console.log( 'dataForm-->', dataForm )
      const res = await apiService.saveMedUse( dataForm );
      if ( res.success === true ) {
        saved();
        handleClose();
      }
      Alert(res.success, res.message);
    }    
  }

  useEffect(() => {
    if ( show ) {
      setDataForm( data );
      console.log( 'data', data );
      IsNew = !data?.type;
      if(data == null){
        handleChange({ target: { name: "type", value: 'วิธีใช้ยา' } });
      }
    } else {
      setDataForm( null );
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{IsNew ? `เพิ่มรายการ` : `แก้ไข ${dataForm?.type}: ${dataForm?.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className={"row row-cols"} onSubmit={( e ) => {
            e.preventDefault();
            handleSave().then();
          }}>
            <Form.Group className="mb-3" controlId="type">
              <Form.Label className={"me-2"}>ประเภท</Form.Label>
              <div className={"h5"}>
                <Form.Check
                  onChange={handleChange}
                  type="radio"
                  inline
                  label={"วิธีใช้ยา"}
                  value={"วิธีใช้ยา"}
                  name={"type"}
                  id={"radio-type-1"}
                  checked={dataForm?.type ? (dataForm?.type === 'วิธีใช้ยา') : true}
                />
                <Form.Check
                  onChange={handleChange}
                  type="radio"
                  inline
                  label={"เวลาที่ใช้"}
                  value={"เวลาที่ใช้"}
                  name={"type"}
                  id={"radio-type-2"}
                  checked={dataForm?.type === 'เวลาที่ใช้'}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="code">
              <Form.Label>รหัส</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Code"
                onChange={handleChange}
                name={"code"}
                value={dataForm?.code}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>{dataForm?.type}</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder={dataForm?.name}
                onChange={handleChange}
                name={"name"}
                value={dataForm?.name}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="mainCalculate">
              <Form.Label>จำนวนนาที <small>เพื่อใช้ในการคำนวนยาเคมี</small></Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="นาที"
                onChange={handleChange}
                name={"mainCalculate"}
                value={dataForm?.mainCalculate}
              />
            </Form.Group>
            <div className="w-100 text-end">
              <Button variant="secondary" size="lg" onClick={handleClose}>
                ปิด
              </Button>{" "}
              <Button variant="primary" type="submit" size="lg">
                บันทึก
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>    
    </>
  );
}
