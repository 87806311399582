import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { apiService } from '../functions/api-service';
import { Alert, HandleChange, OptionSelects } from '../functions/utils';
import {ChangeEvent, changPass, Department, User,} from '../type';
import {useContexts} from "../context/AppContext";
import Swal from "sweetalert2";

type Props = {
  show: boolean
  userId:number
  handleClose: () => void;
  saved: () => void;
  setUserId: (userId: number) => void
};

export default function ModalChangPassword ( { show,  handleClose, saved,userId }: Props ) {
  let IsNew = true;
  const [dataForm, setDataForm] = useState<changPass | null>( null );
  const handleChange = (e: ChangeEvent) => HandleChange(setDataForm, e);
  const { logout } = useContexts();
  const handleSave = async () => {
    console.log( 'dataForm_>', dataForm )
    if(dataForm){
      // if(!IsNew){
      //   delete (dataForm.password)
      // }
      if(dataForm.passwordNew === dataForm.passwordConfirm) {
        dataForm.userId = userId;
        const res = await apiService.updatePass(dataForm);
        if (res.success === true) {
          saved();
          handleClose();
          logout();
        }
        Alert(res.success, res.message);
      }else {
        await Swal.fire(
            'รหัสผ่านใหม่ไม่ตรงกัน',
            'กรุณาลองใหม่อีกครั้ง',
            'warning'
        )
      }
    }    
  }

  useEffect(() => {
    if ( show ) {
      console.log( 'userId', userId );
      IsNew = !userId;
    } else {
      setDataForm( null );
    }
  }, [show]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`กรุณาแก้ไขข้อมูล Password ใหม่`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className={"row row-cols"} onSubmit={( e ) => {
            e.preventDefault();
            handleSave().then();
          }}>

            {<Form.Group className="mb-3" controlId="passwordNew">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                  required
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  name={"passwordNew"}
              />
            </Form.Group>
            }

            <Form.Group className="mb-3" controlId="passwordConfirm">
              <Form.Label>Confirm New Password </Form.Label>
              <Form.Control
                  required
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  name={"passwordConfirm"}
              />
            </Form.Group>

            <hr/>
            <div className="w-100 text-end">
              <Button variant="secondary" size="lg" onClick={handleClose}>
                ปิด
              </Button>{" "}
              <Button variant="primary" type="submit" size="lg">
                บันทึก
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>    
    </>
  );
}
