import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { apiService } from '../functions/api-service';
import { Alert, HandleChange, OptionSelects } from '../functions/utils';
import {ChangeEvent, Department, DrugMac,} from '../type';

type Props = {
  show: boolean;
  data: DrugMac | null;
  handleClose: () => void;
  saved: () => void;
};

export default function ModalDrugMac ( { show, data, handleClose, saved }: Props ) {
  let IsNew = true;
  const [dataForm, setDataForm] = useState<DrugMac | null>( null );
  const handleChange = (e: ChangeEvent) => HandleChange(setDataForm, e);

  const handleSave = async () => {
    console.log( 'dataForm', dataForm )
    if(dataForm){
      const res = await apiService.saveDrugMac( dataForm );
      if ( res.success === true ) {
        saved();
        handleClose();
      }
      Alert(res.success, res.message);
    }    
  }

  useEffect(() => {
    if ( show ) {
      setDataForm( data );
      console.log( 'data', data );
      IsNew = data?.itemId ? false : true;
    } else {
      setDataForm( null );
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{IsNew ? `เพิ่มรายการ` : `แก้ไข ${dataForm?.fullName}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className={"row row-cols"} onSubmit={( e ) => {
            e.preventDefault();
            handleSave().then();
          }}>
            <Form.Group className="mb-3" controlId="itemId">
              <Form.Label>รหัส</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Item ID"
                onChange={handleChange}
                name={"itemId"}
                value={dataForm?.itemId}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="keyData">
              <Form.Label>KeyData</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="KeyData"
                onChange={handleChange}
                name={"keyData"}
                value={dataForm?.keyText}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="shortName">
              <Form.Label>Short Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Short name"
                onChange={handleChange}
                name={"shortName"}
                value={dataForm?.shortName}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="fullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="full name"
                onChange={handleChange}
                name={"fullName"}
                value={dataForm?.fullName}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="volume">
              <Form.Label>Volume </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="volume"
                onChange={handleChange}
                name={"volume"}
                value={dataForm?.volume}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="mg">
              <Form.Label>Mg</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="mg"
                onChange={handleChange}
                name={"mg"}
                value={dataForm?.mass}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="gravity">
              <Form.Label>Gravity</Form.Label>
              <Form.Control
                  type="text"
                  placeholder="gravity"
                  onChange={handleChange}
                  name={"gravity"}
                  value={dataForm?.gravity}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="density">
              <Form.Label>Density</Form.Label>
              <Form.Control
                  type="text"
                  placeholder="density"
                  onChange={handleChange}
                  name={"density"}
                  value={dataForm?.density}
              />
            </Form.Group>


            <Form.Group className="mb-3" controlId="picName">
              <Form.Label>Picture Name</Form.Label>
              <Form.Control
                  required
                  type="text"
                  placeholder="Picture Name"
                  onChange={handleChange}
                  name={"picName"}
                  value={dataForm?.imageName}
              />
            </Form.Group>




            <div className="w-100 text-end">
              <Button variant="secondary" size="lg" onClick={handleClose}>
                ปิด
              </Button>{" "}
              <Button variant="primary" type="submit" size="lg">
                บันทึก
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>    
    </>
  );
}
